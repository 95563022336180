import { Subject } from 'rxjs';
export class MessageService {
    private showconfirmedmessage = false;
    private showbackdmessage = false;
    public confirmmessage = false;
    public backmessage = false;
    public title: string;
    public noIcon = false;
    public body: string;
    public approveMessage = 'yes';
    public rejectMessage = 'no';
    public successMessage = 'ok';
    public messageType: MessageType;
    public confirmedresult = false;
    public backresult = false;
    private messageSubject = new Subject<MessageState>();
    public dialogConfirmation: () => void;
    public dialogRejection: () => void;

    messageState = this.messageSubject.asObservable();

    public showmessage(title: string, body: string, messageType?: MessageType, successMessage?: string) {
        this.confirmmessage = false;
        this.backmessage = false;
        this.title = title;
        this.body = body;
        if (!messageType) {
            messageType = MessageType.Default;
        }
        if (successMessage) {
            this.successMessage = successMessage;
        } else {
            this.successMessage = 'ok';
        }
        this.messageType = messageType;
        this.show();
    }

    public showconfirm(title: string, body: string, noIcon?: boolean, approveMessage?: string, rejectMessage?: string) {
        this.messageType = MessageType.Default;
        this.confirmmessage = true;
        this.backmessage = true;
        this.title = title;
        this.body = body;
        if (!noIcon) {
            noIcon = false;
        }
        if (approveMessage) {
            this.approveMessage = approveMessage;
        } else {
            this.approveMessage = 'yes';
        }
        if (rejectMessage) {
            this.rejectMessage = rejectMessage;
        } else {
            this.rejectMessage = 'no';
        }
        this.noIcon = noIcon;
        return new Promise<boolean>((resolve, reject) => {

            this.dialogConfirmation = () => resolve(true);
            this.dialogRejection = () => resolve(false);
            this.show();
        }).catch(err => {});
    }


    private show() {
        this.messageSubject.next(<MessageState>{ show: true });
        this.showconfirmedmessage = false;
        this.showbackdmessage = false;
    }

    hide() {
        this.messageSubject.next(<MessageState>{ show: false });
        this.showconfirmedmessage = true;
        this.showbackdmessage = true;

    }
}

export interface MessageState {
    show: boolean;
}

export enum MessageType {
    Default = 0,
    Attention = 1
}
