import { UUID } from 'angular2-uuid';

export class NotificationReadModel {
    id?: string;
    actionType?: NotificationReadModel.ActionTypeEnum;
    actionTypeDisplayName?: string;
    title?: string;
    message?: string;
    status?: NotificationReadModel.StatusEnum;
    statusDisplayName?: string;
    date?: Date;
    data?: IPushNotificationModel;
    dataModel?: string;
}
export namespace NotificationReadModel {
    export type ActionTypeEnum = 'Unknown' | 'HotAction' | 'Portion' | 'Pickup' | 'Delivery' | 'Reservation' | 'UniqueQR' | 'WithoutMenu' | 'TeamRegistration' | 'Kiosk';
    export const ActionTypeEnum = {
        Unknown: 'Unknown' as ActionTypeEnum,
        HotAction: 'HotAction' as ActionTypeEnum,
        Portion: 'Portion' as ActionTypeEnum,
        Pickup: 'Pickup' as ActionTypeEnum,
        Delivery: 'Delivery' as ActionTypeEnum,
        Reservation: 'Reservation' as ActionTypeEnum,
        UniqueQR: 'UniqueQR' as ActionTypeEnum,
        WithoutMenu: 'WithoutMenu' as ActionTypeEnum,
        TeamRegistration: 'TeamRegistration' as ActionTypeEnum,
        Kiosk: 'Kiosk' as ActionTypeEnum
    };
    export type StatusEnum = 'New' | 'Viewed' | 'Closed';
    export const StatusEnum = {
        New: 'New' as StatusEnum,
        Viewed: 'Viewed' as StatusEnum,
        Closed: 'Closed' as StatusEnum
    };
}


export interface IPushNotificationModel {
    readonly actionType?: IPushNotificationModel.ActionTypeEnum;
    buyerId?: string;
    checkoutId?: string;
    orderNumber?: string;
    getActionId(): string;
}
export namespace IPushNotificationModel {
    export type ActionTypeEnum = 'Unknown' | 'HotAction' | 'Portion' | 'Pickup' | 'Delivery' | 'Reservation' | 'UniqueQR' | 'WithoutMenu' | 'TeamRegistration' | 'Kiosk';
    export const ActionTypeEnum = {
        Unknown: 'Unknown' as ActionTypeEnum,
        HotAction: 'HotAction' as ActionTypeEnum,
        Portion: 'Portion' as ActionTypeEnum,
        Pickup: 'Pickup' as ActionTypeEnum,
        Delivery: 'Delivery' as ActionTypeEnum,
        Reservation: 'Reservation' as ActionTypeEnum,
        UniqueQR: 'UniqueQR' as ActionTypeEnum,
        WithoutMenu: 'WithoutMenu' as ActionTypeEnum,
        TeamRegistration: 'TeamRegistration' as ActionTypeEnum,
        Kiosk: 'Kiosk' as ActionTypeEnum
    };
}

export enum ENotificatoinOrderStatus {
    Unknown = 'Unknown',
    ActiveOrderCheckout = 'ActiveOrderCheckout',
    ExpireCheckout = 'ExpireCheckout',
    ActivOrderRemovedCheckout = 'ActivOrderRemovedCheckout',
    ActivOrderCanceledCheckout = 'ActivOrderCanceledCheckout',
    ActivOrderApprovedCheckout = 'ActivOrderApprovedCheckout',
    Paid = 'Paid',
    Created = 'Created',
    AwaitingValidation = 'AwaitingValidation',
    StockConfirmed = 'StockConfirmed',
    Cancelled = 'Cancelled',
    Shipped = 'Shipped',
    Completed = 'Completed',
    HotActionStatusSended = 'HotActionStatusSended',
    HotActionStatusApproved = 'HotActionStatusApproved',
    HotActionStatusCancelled = 'HotActionStatusCancelled',
    HotActionStatusDenied = 'HotActionStatusDenied',
    StatusChanged = 'StatusChanged'
}

export enum ENotificatoinHotActionStatus {
    Unknown = 'Unknown',
    Sended = 'Sended',
    Approved = 'Approved',
    Cancelled = 'Cancelled'
}


export class PortionPushNotificationModel implements IPushNotificationModel {
    readonly actionType?: IPushNotificationModel.ActionTypeEnum = IPushNotificationModel.ActionTypeEnum.Portion;
    checkoutId: string;
    groupId: string;
    groupName: string;
    qrId: string;
    buyerId: string;
    orderNumber: string;
    status: ENotificatoinOrderStatus;
    getActionId(): string {
        if (this.orderNumber) {
            return this.orderNumber;
        }
        if (this.checkoutId) {
            return this.checkoutId;
        }
        return UUID.UUID();
    }
}
export class PickupPushNotificationModel implements IPushNotificationModel {
    readonly actionType?: IPushNotificationModel.ActionTypeEnum = IPushNotificationModel.ActionTypeEnum.Pickup;
    checkoutId: string;
    buyerId: string;
    orderNumber: string;
    status: ENotificatoinOrderStatus;
    getActionId(): string {
        if (this.orderNumber) {
            return this.orderNumber;
        }
        if (this.checkoutId) {
            return this.checkoutId;
        }
        return UUID.UUID();
    }
}
export class DeliveryPushNotificationModel implements IPushNotificationModel {
    readonly actionType?: IPushNotificationModel.ActionTypeEnum = IPushNotificationModel.ActionTypeEnum.Delivery;
    checkoutId: string;
    buyerId: string;
    orderNumber: string;
    status: ENotificatoinOrderStatus;
    getActionId(): string {
        if (this.orderNumber) {
            return this.orderNumber;
        }
        if (this.checkoutId) {
            return this.checkoutId;
        }
        return UUID.UUID();
    }
}
export class ReservationPushNotificationModel implements IPushNotificationModel {
    readonly actionType?: IPushNotificationModel.ActionTypeEnum = IPushNotificationModel.ActionTypeEnum.Reservation;
    checkoutId: string;
    buyerId: string;
    orderNumber: string;
    reservationDate?: Date;
    status: ENotificatoinOrderStatus;
    getActionId(): string {
        if (this.orderNumber) {
            return this.orderNumber;
        }
        if (this.checkoutId) {
            return this.checkoutId;
        }
        return UUID.UUID();
    }
}
export class WithoutMenuPushNotificationModel implements IPushNotificationModel {
    readonly actionType?: IPushNotificationModel.ActionTypeEnum = IPushNotificationModel.ActionTypeEnum.WithoutMenu;
    checkoutId: string;
    buyerId: string;
    orderNumber: string;
    reservationDate?: Date;
    status: ENotificatoinOrderStatus;
    getActionId(): string {
        if (this.orderNumber) {
            return this.orderNumber;
        }
        if (this.checkoutId) {
            return this.checkoutId;
        }
        return UUID.UUID();
    }
}
export class UniqueQRPushNotificationModel implements IPushNotificationModel {
    readonly actionType?: IPushNotificationModel.ActionTypeEnum = IPushNotificationModel.ActionTypeEnum.UniqueQR;
    checkoutId: string;
    buyerId: string;
    orderNumber: string;
    status: ENotificatoinOrderStatus;
    getActionId(): string {
        if (this.orderNumber) {
            return this.orderNumber;
        }
        if (this.checkoutId) {
            return this.checkoutId;
        }
        return UUID.UUID();
    }
}
export class KioskPushNotificationModel implements IPushNotificationModel {
    readonly actionType?: IPushNotificationModel.ActionTypeEnum = IPushNotificationModel.ActionTypeEnum.Kiosk;
    checkoutId: string;
    buyerId: string;
    orderNumber: string;
    receiptNumber: string;
    status: ENotificatoinOrderStatus;
    getActionId(): string {
        if (this.orderNumber) {
            return this.orderNumber;
        }
        if (this.checkoutId) {
            return this.checkoutId;
        }
        return UUID.UUID();
    }
}
export class DefaultPushNotificationModel implements IPushNotificationModel {
    readonly actionType?: IPushNotificationModel.ActionTypeEnum = IPushNotificationModel.ActionTypeEnum.Unknown;
    getActionId(): string {
        return UUID.UUID();
    }
}
export class HotActionPushNotificationModel implements IPushNotificationModel {
    header: string;
    groupId: string;
    qrId: string;
    groupName: string;
    groupNameKey: string;
    pictureUrl: string;
    actionType: any;
    status: ENotificatoinHotActionStatus;
    getActionId(): string {
        return UUID.UUID();
    }
}

export class TeamRegistrationPushNotificationModel implements IPushNotificationModel {
    readonly actionType?: IPushNotificationModel.ActionTypeEnum = IPushNotificationModel.ActionTypeEnum.TeamRegistration;
    buyerId: string;
    teamId: string;
    email: string;
    host: string;
    fullHost: string;
    teamName: string;
    phoneNumber: string;
    countryCode: string;
    companyTypeId: string;
    registrationDate: Date;
    getActionId(): string {
        return this.teamId;
    }
}
