import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import * as jwt_decode from 'jwt-decode';
import { AppInsights } from 'applicationinsights-js';
import { ConfigService } from './services/config.service';
import { USED_LANGUAGES } from './shared/loaders/translationloader';
import { AuthService } from './services/auth.service';
import { StorageKeys } from './shared/services/constant.service';
import { HelperService } from './shared/services/helper.service';
import { TranslationService } from './generatedmodules/translation/api';
import { User } from './shared/models/User';
import { PaginationTitleService } from './shared/services/paginationtitle.service';
import { TranslationLanguageService } from './services/translationlanguage.service';
import { StorageService } from './services/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  isinit = false;
  auth = false;
  defaultLangsLoaded = false;

  jivosites = '';
  documentTitle = '';
  documentIcon = '';
  appThemes = '';
  currentCountryCodeLoaded = false;
  private themeWrapper = document.querySelector('body');

  constructor(
    private authService: AuthService,
    private router: Router,
    private translationlanguageService: TranslationLanguageService,
    private translationService: TranslationService,
    private storageService: StorageService,
    private paginationTitleService: PaginationTitleService,
    private configService: ConfigService,
    private helperService: HelperService
  ) {
    this.documentTitle = this.configService.get('documentTitle');
    this.documentIcon = this.configService.get('documentIcon');
    const salePortalKey = this.configService.get('SalePortalKey');
    this.appThemes = this.configService.get('app-themes');
    const styles = this.configService.get('styles') as { key: string, value: string }[];
    styles.forEach(style => {
      this.themeWrapper.style.setProperty('--' + style.key, style.value);
    });
    this.storageService.store(StorageKeys.SALE_PORTAL_KEY, salePortalKey);
    this.paginationTitleService.reset();
    this.paginationTitleService.setTitle(this.documentTitle);
    this.paginationTitleService.setImage(this.documentIcon);
    this.jivosites = this.configService.get('jivoChat');
    this.paginationTitleService.setjivosite(this.jivosites);
    this.defaultLangsLoaded = false;
    USED_LANGUAGES.splice(0, USED_LANGUAGES.length);
    this.auth = this.authService.checkTokenValidation();
    this.authService.authorized.subscribe(
      val => {
        if (this.auth === val) {
          return;
        }
        if (val === false) {
          this.auth = false;
          this.unAuthData();
        }
        if (val === true) {
          this.authData();
        }
      },
      err => { }
    );
    this.router.events.subscribe(res => {
      if (res instanceof NavigationStart) {
        if ((res.url === '/' || res.url === '/home') && !this.auth) {
          this.router.navigate(['/profile/login']);
        }
        if (!this.isinit) {
          this.isinit = true;
          if (this.auth === false) {
            const paramLang = this.helperService.getParamValue(res.url, 'lang');
            if (paramLang) {
              this.unAuthData(paramLang);
            } else {
              // if (!this.currentCountryCodeLoaded) {
              //   this.translationService.apiV1TranslationLangsCurrentcountryGet('1.0').subscribe(currentCountryCode => {
              //     this.storageService.store(StorageKeys.CURRENT_COUNTRY_CODE, currentCountryCode);
              //     this.currentCountryCodeLoaded = true;
              //     this.unAuthData(currentCountryCode);
              //   });
              // } else {
              //   this.unAuthData(this.currentCountryCode);
              // }
              this.unAuthData('');
            }
          }
          if (this.auth === true) {
            this.authData();
          }
        }
      }
    });
    AppInsights.queue.push(() => {
      AppInsights.context.addTelemetryInitializer(
        (envelope: Microsoft.ApplicationInsights.IEnvelope) => {
          envelope.tags['ai.device.roleName'] = 'Portal';
        }
      );
    });
  }

  unAuthData(paramLang?: string) {
    if (window.screen.width <= 767) {
      paramLang = 'us';
    }
    this.auth = false;
    User.clear();
    this.isinit = false;
    if (!this.defaultLangsLoaded) {
      this.translationlanguageService.loadLanguages(paramLang);
      this.translationlanguageService.languages.subscribe(res => {
        if (res && res.length > 0) {
          let language = this.storageService.retrieve(StorageKeys.TranslationLanguageKey);
          if (!language || !language.trim()) {
            language = res[0].name;
            this.storageService.store(StorageKeys.TranslationLanguageKey, language);
          }
          this.defaultLangsLoaded = true;
        }
      });
    }
  }

  authData() {
    const decoded = jwt_decode<any>(this.storageService.retrieve('access_token'));
    User.create(decoded);
    this.auth = true;
  }
}
