import { Inject } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { DirectTranslationService, TranslationService } from 'src/applazy/generatedmodules/translation/api';
import { StorageService } from 'src/applazy/services/storage.service';
import { AuthService } from '../../services/auth.service';
import { User } from '../models/User';
import { StorageKeys } from '../services/constant.service';

export const USED_LANGUAGES: Array<string> = [];
export class TranslationLoader implements TranslateLoader {
    constructor(
        @Inject(StorageService) private storageService: StorageService,
        @Inject(TranslationService) private translationService: TranslationService,
        @Inject(DirectTranslationService) private directTranslationService: DirectTranslationService,
        @Inject(AuthService) private authService: AuthService
    ) {
    }

    getTranslation(lang: string): Observable<any> {
        if (USED_LANGUAGES.indexOf(lang) === -1) {
            USED_LANGUAGES.push(lang);
        }
        const host = this.storageService.retrieve(StorageKeys.SALE_PORTAL_TRANSLATE_HOST, true);
        if (this.authService.isAuthorized) {
            if (User.Role === 'SalePortal') {
                if (!host) {
                    return this.directTranslationService.apiV1DirectTranslationPortalLanguageGet(lang, '1.0');
                }
                return this.directTranslationService.apiV1DirectTranslationHostLanguageGet(lang, host, '1.0');
            } else {
                return this.translationService.apiV1TranslationLanguageHostHostLanguageGet(lang, '1.0');
            }
        }
        return this.translationService.apiV1TranslationLangLanguageGet(lang, '1.0');
    }
}
