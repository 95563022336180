export enum YoutubeVideoURLFormats {
        None = 0,
        Watch = 1,
        Be = 2,
        Embed = 3
}
export class HelperService {
        youtubeVideoURLFormats: { type: YoutubeVideoURLFormats, prefix: string }[] = [
                { type: YoutubeVideoURLFormats.Watch, prefix: 'https://www.youtube.com/watch?v=' },
                { type: YoutubeVideoURLFormats.Be, prefix: 'https://youtu.be/' },
                { type: YoutubeVideoURLFormats.Embed, prefix: 'https://www.youtube.com/embed/' },
        ];
        b64toBlob(b64Data, contentType, sliceSize): Blob {
                contentType = contentType || '';
                sliceSize = sliceSize || 512;

                const byteCharacters = atob(b64Data);
                const byteArrays = [];

                for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                        const slice = byteCharacters.slice(offset, offset + sliceSize);

                        const byteNumbers = new Array(slice.length);
                        for (let i = 0; i < slice.length; i++) {
                                byteNumbers[i] = slice.charCodeAt(i);
                        }

                        const byteArray = new Uint8Array(byteNumbers);

                        byteArrays.push(byteArray);
                }

                const blob = new Blob(byteArrays, { type: contentType });
                return blob;
        }

        getdateformat(format: string): any {
                let dtformat = 'MM/dd/yyyy';

                if (format === 'PKDateStyleNone') {
                        dtformat = '';
                }
                /// <summary>
                /// Specifies a short style, typically numeric only, such as "11/23/37" or "3:30 PM".
                /// </summary>
                if (format === 'PKDateStyleShort') {
                        dtformat = 'MM/dd/yy';
                }
                /// <summary>
                /// Specifies a medium style, typically with abbreviated text, such as "Nov 23, 1937" or "3:30:32 PM".
                /// </summary>
                if (format === 'PKDateStyleMedium') {
                        dtformat = 'MMM/dd/yyyy';
                }
                /// <summary>
                /// Specifies a long style, typically with full text, such as "November 23, 1937" or "3:30:32 PM PST".
                /// </summary>
                if (format === 'PKDateStyleLong') {
                        dtformat = 'MMMM d, y';
                }

                /// <summary>
                /// Specifies a full style with complete details, such as "Tuesday, April 12, 1952 AD" or "3:30:42 PM Pacific Standard Time".
                /// </summary>

                if (format === 'PKDateStyleFull') {
                        dtformat = 'EEEE, MMMM d, y';
                }


                return dtformat;
        }

        gettimeformat(format: string): any {
                let dtformat = '';
                if (format === 'PKDateStyleNone') {
                        dtformat = '';
                }
                /// <summary>
                /// Specifies a short style, typically numeric only, such as "11/23/37" or "3:30 PM".
                /// </summary>
                if (format === 'PKDateStyleShort') {
                        dtformat = ' h:mm';
                }
                /// <summary>
                /// Specifies a medium style, typically with abbreviated text, such as "Nov 23, 1937" or "3:30:32 PM".
                /// </summary>
                if (format === 'PKDateStyleMedium') {
                        dtformat = ' h:mm:s';
                }
                /// <summary>
                /// Specifies a long style, typically with full text, such as "November 23, 1937" or "3:30:32 PM PST".
                /// </summary>
                if (format === 'PKDateStyleLong') {
                        dtformat = ' h:mm:ss';
                }

                /// <summary>
                /// Specifies a full style with complete details, such as "Tuesday, April 12, 1952 AD" or "3:30:42 PM Pacific Standard Time".
                /// </summary>

                if (format === 'PKDateStyleFull') {
                        dtformat = ' h:mm:sss';
                }

                return dtformat;
        }

        printCanvas(cnv: any, title: string, transformDateStart: string, transformDateEnd: string): void {
                let printContents, popupWin;
                const img = cnv.toDataURL();
                printContents = '<br><img src="' + img + '"/>';
                popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
                popupWin.document.open();
                popupWin.document.write(`
          <html>
            <head>
              <title>${title}</title>
              <style>
              //........Customized style.......
              </style>
            </head>
        <body onload="window.print();window.close()">
        <b style=" text-align: center;">${transformDateStart} - ${transformDateEnd} </b>
        <div>${printContents}</div></body>
          </html>`
                );
                popupWin.document.close();
        }

        getTranslateNameByCountry(countryName: string): string {
                switch (countryName) {
                        case 'Armenia':
                                return 'arm';
                        case 'Belarus':
                                return 'by';
                        case 'Georgia':
                                return 'ka';
                        case 'Germany':
                                return 'de';
                        case 'Iran':
                                return 'ir';
                        case 'Italy':
                                return 'itl';
                        case 'Russia':
                                return 'ru';
                        case 'United Kingdom':
                        case 'United States':
                                return 'en';
                        default:
                                return 'en';
                }
        }


        private checkIfYoutubeUrl(url: string): YoutubeVideoURLFormats {
                if (!url || !url.trim()) {
                        return YoutubeVideoURLFormats.None;
                }
                let exist = YoutubeVideoURLFormats.None;
                for (let i = 0; i < this.youtubeVideoURLFormats.length; i++) {
                        if (url.toLowerCase().indexOf(this.youtubeVideoURLFormats[i].prefix.toLowerCase()) === 0) {
                                exist = this.youtubeVideoURLFormats[i].type;
                                break;
                        }
                }
                return exist;
        }

        getYoutubeEmbedUrl(url: string): string {
                const type = this.checkIfYoutubeUrl(url);
                if (type === YoutubeVideoURLFormats.None) {
                        return url;
                }
                let newUrl: string = url;
                if (newUrl.indexOf('&') === -1) {
                        newUrl += '&';
                }
                const embedModel = this.youtubeVideoURLFormats.find(x => x.type === YoutubeVideoURLFormats.Embed);
                let model: { type: YoutubeVideoURLFormats, prefix: string };
                switch (type) {
                        case YoutubeVideoURLFormats.Watch:
                                model = this.youtubeVideoURLFormats.find(x => x.type === type);
                                break;
                        case YoutubeVideoURLFormats.Be:
                                model = this.youtubeVideoURLFormats.find(x => x.type === type);
                                break;
                        case YoutubeVideoURLFormats.Embed:
                                return url;
                        default:
                                return url;
                }

                newUrl = newUrl.replace(model.prefix, '');
                newUrl = newUrl.substring(0, newUrl.indexOf('&'));
                return embedModel.prefix + newUrl;
        }

        toUniqueStr(val: string): string {
                let guidString = btoa(val);
                guidString = guidString.replace('=', '');
                guidString = guidString.replace('+', '');
                guidString = guidString.replace('/', '');

                return guidString;
        }

        getParamValue(url: string, key: string): string {
                let val = '';
                const regex = new RegExp('[?&]' + key + '(=([^&#]*)|&|#|$)');
                const results = regex.exec(url);
                if (results && results[2]) {
                        val = decodeURIComponent(results[2].replace(/\+/g, ' '));
                }

                return val;
        }

        getStandartFormat(startDate: Date, end: boolean): Date {
                if (navigator.userAgent.search('Safari') >= 0 && navigator.userAgent.search('Chrome') < 0) {
                        let dateSafari = (startDate.getMonth() + 1) + '/' + startDate.getDate() + '/' + startDate.getFullYear();
                        if (end) {
                                dateSafari = '23:59:59 ' + dateSafari;
                        }
                        return new Date(dateSafari.replace(/-/g, '/'));
                }
                let date = '' + (startDate.getMonth() + 1) + '/' + startDate.getDate() + '/' + startDate.getFullYear() + 'Z';
                if (end) {
                        date = '23:59:59 ' + date;
                }
                return new Date(date);

        }

}
