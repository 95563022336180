import { Injectable } from '@angular/core';
import { features } from 'src/applazy/shared/models/profilemodels';
import { AuthService } from 'src/applazy/services/auth.service';
import { NavigationModel, NavigationType } from '../models/navigation.models';
import { INavigationItemsService } from './iNavigationItemsService';

@Injectable({
  providedIn: 'root'
})
export class NavigationMenuService {

  private _navigationItemsService: INavigationItemsService;
  private _navigations: Array<NavigationModel> = [];
  public get navigations(): Array<NavigationModel> {
    return this._navigations;
  }
  private _panelNavigations: Array<NavigationModel> = [];
  public get panelNavigations(): Array<NavigationModel> {
    return this._panelNavigations;
  }
  constructor(
    private auth: AuthService
  ) {
    this.auth.authorized.subscribe(res => {
      if (!res && (this._navigations || this._panelNavigations)) {
        this._navigations = [];
        this._panelNavigations = [];
      }
    });
  }

  generateNavigations(navigationItemsService: INavigationItemsService): void {
    if (!this._navigationItemsService) {
      this._navigationItemsService = navigationItemsService;
      this.subscribeToEvents();
      this._navigations = navigationItemsService.getNavigations() || [];
      this._panelNavigations = navigationItemsService.getPanelNavigations() || [];
    }
  }

  findById(id: string): NavigationModel {
    return this._findById(id, this._navigations);
  }

  openParents(navigation: NavigationModel) {
    this._openParents(navigation);
  }

  private _openParents(navigation: NavigationModel) {
    if (!navigation.parentId) {
      return;
    }
    const model = this.findById(navigation.parentId);
    if (model.opened) {
      return;
    }
    model.opened = true;
    this._openParents(model);
  }

  private _findById(id: string, array: NavigationModel[]): NavigationModel {
    let item = array.find(x => x.id === id);
    if (item) {
      return item;
    }
    for (let i = 0; i < array.length; i++) {
      if (array[i].childs && array[i].childs.length > 0) {
        item = this._findById(id, array[i].childs);
        if (item) {
          break;
        }
      }
    }
    return item;
  }

  private subscribeToEvents() {
    this._navigationItemsService.navigationSaved.subscribe((res: { model: NavigationModel, type: NavigationType, position: number }) => {
      switch (res.type) {
        case NavigationType.MainNavigation:
          this._saveMainNavigation(res.model, res.position);
          break;
        case NavigationType.PanelNavigation:
          this._savePanelNavigation(res.model, res.position);
          break;
        default:
          break;
      }
    });
  }

  private _saveMainNavigation(model: NavigationModel, position: number): void {
    let navigationModel: NavigationModel;
    let arr: NavigationModel[];
    let index = -1;
    if (model.parentId) {
      const parent = this._findById(model.parentId, this._navigations);
      if (!parent) {
        return;
      }
      navigationModel = parent.childs.find(x => x.id === model.id);
      arr = parent.childs;
    } else {
      navigationModel = this._navigations.find(x => x.id === model.id);
      arr = this._navigations;
    }
    if (!navigationModel) {
      if (position >= 0) {
        arr.splice(position, 0, model);
      } else {
        arr.push(model);
      }
    } else {
      index = arr.indexOf(navigationModel);
      arr[index] = model;
    }
  }

  private _savePanelNavigation(model: NavigationModel, position: number): void {
  }
}
