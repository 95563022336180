import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { LoaderState } from '../shared/components/loader/loader';

@Injectable()
export class LoaderService {

    private loaderSubject = new Subject<LoaderState>();
    loaderStateModel: boolean;
    isPost: boolean;
    loaderState = this.loaderSubject.asObservable();

    constructor() { }

    show(isPost: boolean) {
        this.isPost = isPost;
        this.loaderStateModel = true;
        this.loaderSubject.next(<LoaderState>{show: true});

    }

    hide() {
        this.loaderStateModel = false;
        this.loaderSubject.next(<LoaderState>{show: false});
    }
}
