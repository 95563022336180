import { Injectable, Inject } from '@angular/core';
import { GoogleMapsScriptProtocol, MapsAPILoader, LazyMapsAPILoaderConfigLiteral, LAZY_MAPS_API_CONFIG } from '@agm/core';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { TranslationLanguageService } from 'src/applazy/services/translationlanguage.service';
import { ConfigService } from 'src/applazy/services/config.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class CustomMapsAPILoader extends MapsAPILoader {
    private _script: any;
    private _scriptLoadingPromise: Promise<void>;
    private _config: LazyMapsAPILoaderConfigLiteral;
    // private _windowRef: WindowRef;
    // private _documentRef: DocumentRef;
    private _translateService: TranslateService;
    private _translationLanguageService: TranslationLanguageService;
    private _manifestConfigurationService: ConfigService;


    constructor(
        @Inject(LAZY_MAPS_API_CONFIG) config: any,
        // w: WindowRef,
        // d: DocumentRef,
        @Inject(DOCUMENT) private document: Document,
        @Inject(TranslationLanguageService) translationLanguageService: TranslationLanguageService,
        @Inject(TranslateService) translateService: TranslateService,
        @Inject(ConfigService) manifestConfigurationService
    ) {
        super();
        this._config = config || {};
        // this._windowRef = w;
        // this._documentRef = d;
        this._translateService = translateService;
        this._translationLanguageService = translationLanguageService;
        this._manifestConfigurationService = manifestConfigurationService;
        this._translationLanguageService.mainPanelLanguageChanged.subscribe(res => {
            setTimeout(() => {
                const w = window as any;
                if (w.google) {
                    w.google.maps = null;
                }
                const element = this.document.getElementById('googleMapApiLoader');
                if (element) {
                    element.remove();
                }
                this._scriptLoadingPromise = undefined;
                this._script = undefined;
            }, 200);
        });
    }

    load(): Promise<void> {
        const lang = this._translationLanguageService.authLanguagesArr.find(x => x.name === this._translateService.currentLang);
        if (this._scriptLoadingPromise) {
            return this._scriptLoadingPromise;
        }
        if (lang) {
            this._config.language = lang.googleName;
        }
        // this._config.region = this._profileDataService.configurationModel.countryCode;
        const callbackName = `agmLazyMapsAPILoader`;
        this._script = this.document.createElement('script');
        this._script.id = 'googleMapApiLoader';
        this._script.type = 'text/javascript';
        this._script.async = true;
        this._script.defer = true;
        this._script.src = this._getScriptSrc(callbackName);
        this.document.body.appendChild(this._script);
        this._scriptLoadingPromise = new Promise<void>((resolve: Function, reject: Function) => {
            const w = window as any;
            const a = w[callbackName];
            w[callbackName] = undefined;
            (<any>w)[callbackName] = () => { resolve(); };

            this._script.onerror = (error: Event) => { reject(error); };
        }).catch(err => { });
        return this._scriptLoadingPromise;
    }

    private _getScriptSrc(callbackName: string): string {
        const protocolType: GoogleMapsScriptProtocol =
            (this._config && this._config.protocol) || GoogleMapsScriptProtocol.HTTPS;
        let protocol: string;

        switch (protocolType) {
            case GoogleMapsScriptProtocol.AUTO:
                protocol = '';
                break;
            case GoogleMapsScriptProtocol.HTTP:
                protocol = 'http:';
                break;
            case GoogleMapsScriptProtocol.HTTPS:
                protocol = 'https:';
                break;
        }

        const hostAndPath: string = this._config.hostAndPath || 'maps.googleapis.com/maps/api/js';
        const queryParams: { [key: string]: string | Array<string> } = {
            v: this._config.apiVersion || '3',
            callback: callbackName,
            key: this._getKey(),
            client: this._config.clientId,
            channel: this._config.channel,
            libraries: this._config.libraries,
            region: this._config.region,
            language: this._config.language
        };
        const params: string =
            Object.keys(queryParams)
                .filter((k: string) => queryParams[k] != null)
                .filter((k: string) => {
                    // remove empty arrays
                    return !Array.isArray(queryParams[k]) ||
                        (Array.isArray(queryParams[k]) && queryParams[k].length > 0);
                })
                .map((k: string) => {
                    // join arrays as comma seperated strings
                    const i = queryParams[k];
                    if (Array.isArray(i)) {
                        return { key: k, value: i.join(',') };
                    }
                    return { key: k, value: queryParams[k] };
                })
                .map((entry: { key: string, value: string }) => `${entry.key}=${entry.value}`)
                .join('&');
        return `${protocol}//${hostAndPath}?${params}`;
    }
    private _getKey() {
        if (!environment.production) {
            return environment.googleApiKey;
        }
        if (this._manifestConfigurationService.get('googlemapKey')) {
            return this._manifestConfigurationService.get('googlemapKey');
        }
        return this._config.apiKey;
    }
}

export class WindowRef {
    getNativeWindow(): any { return window; }
}

export class DocumentRef {
    getNativeDocument(): any { return document; }
}
