import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from '../components/messagebox/services/message.service';
import { ComparableModel } from '../models/compare/comparable.model';
const isEqual = require('lodash.isequal');

@Injectable()
export class NavigationService {
    constructor(
        private router: Router,
        private location: Location,
        private translateService: TranslateService,
        private messageService: MessageService
    ) {
    }
    private isRouted$ = new Subject<any>();
    isRouted = this.isRouted$.asObservable();
    private _beforeChanges: Array<string> = [];
    public set beforeChanges(c: Array<any>) {
        this._beforeChanges = c || [];
    }
    public get beforeChanges(): Array<any> {
        return this._beforeChanges;
    }

    private canShowMainQr$ = new Subject();
    canShowMainQr = this.canShowMainQr$.asObservable();
    canShowMain = false;
    private cacheClicked$ = new Subject();
    cacheClicked = this.cacheClicked$.asObservable();

    private refreshClicked$ = new Subject();
    refreshClicked = this.refreshClicked$.asObservable();
    component: any;

    showMainQr(show = true) {
        this.canShowMain = show;
        this.canShowMainQr$.next();
    }

    cache() {
        this.cacheClicked$.next();
    }

    refreshData() {
        this.refreshClicked$.next();
    }

    navigateTo(url: string, param?: string) {
        this.refresh();
        let data = [url];
        if (param) {
            data = [url, param];
        }
        this.router.navigate(data);
        this.isRouted$.next(url);
    }

    navigateWithCompareMessage(url: string) {
        if (this.compareWithOld(this.component)) {
            this.navigateTo(url);
            return;
        } else {
            const translateParams = [];
            translateParams.push('warning');
            translateParams.push('backMessage');
            this.translateService.get(translateParams).subscribe((t) => {
                const t1 = t['warning'];
                const t2 = t['backMessage'];
                this.messageService.showconfirm(t1, t2, true).then((exet) => {
                    if (exet) {
                        this.navigateTo(url);
                    }
                });
            }, err => { });
        }
    }

    back() {
        this.refresh();
        this.location.back();
    }

    refresh() {
        // this.component = null;
        this.beforeChanges = this.getComparableArr(this.component);
    }

    compareWithOld(component: any): boolean {
        const afterChangesArr = this.getComparableArr(component);
        for (let i = 0; i < afterChangesArr.length; i++) {
            if (!isEqual(this.beforeChanges[i], afterChangesArr[i])) {
                return false;
            }
        }
        return true;
    }

    cloneObject(realObj: any, obj: any) {
        for (const key in realObj) {
            if (realObj.hasOwnProperty(key) && key.indexOf('$') < 0) {
                const type = typeof realObj[key];
                if (type === 'object') {
                    obj[key] = Object.assign({}, realObj[key]);
                    this.cloneObject(realObj[key], obj[key]);
                } else {
                    obj[key] = realObj[key];
                }
            }
        }
    }

    getOrdered(unordered: any): any {
        return Object.keys(unordered).sort().reduce(
            (obj, key) => {
                obj[key] = unordered[key];
                this._validateDefaultValue(obj, key);
                return obj;
            },
            {}
        );
    }

    getComparableArr(component: any): Array<string> {
        this.component = component;
        const arr: string[] = [];
        if (!component) {
            return arr;
        }
        this._fillComparableArr(component, arr);
        return arr;
    }
    private _fillComparableArr(model: any, arr: string[]): void {
        if (Array.isArray(model) && model && model.length > 0) {
            model.forEach(item => {
                this._fillComparableArr(item, arr);
            });
        } else {
            for (const key in model) {
                if (Object.prototype.hasOwnProperty.call(model, key)) {
                    if (model[key] instanceof ComparableModel) {
                        const ordered = this.getOrdered(model[key]);
                        const orderedJson = JSON.stringify(ordered);
                        const orderedObj = JSON.parse(orderedJson);
                        // arr.push(orderedJson);
                        arr.push(orderedObj);
                        this._fillComparableArr(orderedObj, arr);
                    }
                }
            }
        }
    }
    private _validateDefaultValue(obj: any, key: string): void {
        if (typeof obj[key] === 'string') {
            if (!obj[key]) {
                obj[key] = '';
            }
        }
        if (typeof obj[key] === 'number') {
            if (!obj[key]) {
                obj[key] = 0;
            }
            let parseNum = Number.parseFloat(obj[key].toString());
            if (parseNum % 1 === 0) {
                parseNum = Number.parseInt(obj[key].toString());
            }
            obj[key] = parseNum;
        }
        if (typeof obj[key] === 'boolean') {
            if (obj[key] === null || obj[key] === undefined) {
                obj[key] = false;
            }
        }
    }
}
