import { ActivatedRoute } from '@angular/router';

export class NavigationModel {
    readonly id: string;
    readonly tabId: string;
    readonly routeWithId: boolean;
    readonly url: string;
    parentId: string;
    name: string;
    picture: string;
    hovered: boolean;
    readonly helpUrl = 'https://help.bonee.net';
    showHelp = true;
    target = '_self';
    activationUrls: string[];
    extraParams: string[];
    private _opened: boolean;
    public set opened(value: boolean) {
        this._opened = value;
        if (!this._opened) {
            this.closeAllChilds();
        }
    }
    public get opened(): boolean {
        return this._opened;
    }

    childs: Array<NavigationModel>;
    private _canShow: boolean;

    public get canShow(): boolean {
        return this._canShow;
    }

    get canShowChilds(): boolean {
        if (!this.childs || this.childs.length === 0) {
            return true;
        }
        const existShowChild = this.childs.findIndex(x => x.canShow);
        return existShowChild > -1;
    }

    get hasChild(): boolean {
        return this.childs && this.childs.length > 0;
    }

    constructor(id: string, tabId: string, url: string, selfFeatures: string[], profileFeatures: string[], activationUrls: string[], extraParams: string[] = []) {
        this.id = id;
        this.tabId = tabId;
        this.parentId = '';
        this.opened = false;
        this.hovered = false;
        this.url = url;
        this.activationUrls = [];
        this.extraParams = [];
        let activationUrl = url;
        if (this.tabId) {
            this.routeWithId = true;
            activationUrl += '/' + this.id;
        }
        if (this.url) {
            this.activationUrls.push(activationUrl);
        }
        activationUrls.forEach(activeUrl => {
            this.activationUrls.push(activeUrl);
        });
        extraParams.forEach(extraParam => {
            this.extraParams.push(extraParam);
        });
        this.childs = [];
        if (!selfFeatures || selfFeatures.length === 0) {
            selfFeatures = [];
            this._canShow = true;
        } else {
            const exist = selfFeatures.filter(selfFeature => profileFeatures.includes(selfFeature));
            if (!exist || exist.length === 0) {
                this._canShow = false;
            } else {
                this._canShow = true;
            }
        }
    }

    closeAllChilds() {
        if (this.hasChild) {
            this.childs.forEach(x => x.opened = false);
        }
    }

    resetShow(show: boolean) {
        this._canShow = show;
    }

    isActive(url: string, route: ActivatedRoute): boolean {
        if (!this.activationUrls || this.activationUrls.length === 0) {
            return false;
        }
        if (!route) {
            return false;
        }
        if (!this.extraParams || this.extraParams.length === 0) {
            return this.activationUrls.findIndex(activeUrl => url.startsWith(activeUrl)) !== -1;
        }
        this.activationUrls.forEach(activeUrl => {
            let aUrl = activeUrl;
            this.extraParams.forEach(extraParam => {
                const value = route.params[extraParam] || '';
                aUrl = aUrl.replace('#' + extraParam, value);
            });
            if (url.startsWith(aUrl)) {
                return true;
            }
        });
        return false;
    }
}

export enum NavigationType {
    MainNavigation = 0,
    PanelNavigation = 2
}
