import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, from as _observableFrom, throwError as _observableThrow, of as _observableOf, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../shared/models/User';

@Injectable()
export class RedirectAdminGuard implements Resolve<Observable<boolean>> {

    constructor(private router: Router) {

    }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Observable<Observable<boolean>> | Promise<Observable<boolean>> {
        const url = state.url;
        if (User.currentRole === 'admin') {
            this.router.navigate(['/admin'], { queryParams: { redirectUrl: url } });
            return from([false]);
        }
        return from([true]);
    }
}
