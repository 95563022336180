import { Injectable, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class PaginationTitleService {
    private _defautlIcon = '../../../assets/images/logo.ico';
    private _defaultTitle = 'QR digital menu and e-commerce';
    constructor(
        private titleService: Title,
        @Inject(DOCUMENT) private document: Document,
    ) {

    }

    reset(): PaginationTitleService {
        this._setTitle(this._defaultTitle);
        this._setImage();

        return this;
    }

    setTitle(title: string): PaginationTitleService {
        this.titleService.setTitle(title);
        return this;
    }
    setImage(image: string): PaginationTitleService {
        this._setImage(image);
        return this;
    }

    private _setTitle(title: string = ''): void {
        this.titleService.setTitle(title || this._defaultTitle);
    }

    private _setImage(image: string = ''): void {
        const link = <HTMLLinkElement>(this.document.querySelector('link[rel*="icon"]') || this.document.createElement('link'));
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = image || this._defautlIcon;
        this.document.getElementsByTagName('head')[0].appendChild(link);
    }


    async setjivosite(id) {
        if (!id) {
            return;
        }
        const jivosite = document.createElement('script');
        jivosite.type = 'text/javascript';
        jivosite.src = '//code.jivosite.com/widget/' + id;
        jivosite.async = true;
        document.getElementsByTagName('head')[0].appendChild(jivosite);
    }
}
