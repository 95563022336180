import { InjectionToken } from '@angular/core';

export interface ISlimScrollState {
    scrollPosition: number;
    isScrollAtStart: boolean;
    isScrollAtEnd: boolean;
  }
  
  export class SlimScrollState implements ISlimScrollState {
    scrollPosition: number;
    isScrollAtStart: boolean;
    isScrollAtEnd: boolean;
    constructor(obj?: ISlimScrollState) {
      this.scrollPosition = obj && obj.scrollPosition ? obj.scrollPosition : 0;
      this.isScrollAtStart = obj && typeof obj.isScrollAtStart !== 'undefined' ? obj.isScrollAtStart : true;
      this.isScrollAtEnd = obj && typeof obj.isScrollAtEnd !== 'undefined' ? obj.isScrollAtEnd : false;
    }
  }


export interface ISlimScrollOptions {
  position?: 'left' | 'right';
  barBackground?: string;
  barOpacity?: string;
  barWidth?: string;
  barBorderRadius?: string;
  barMargin?: string;
  gridBackground?: string;
  gridOpacity?: string;
  gridWidth?: string;
  gridBorderRadius?: string;
  gridMargin?: string;
  alwaysVisible?: boolean;
  visibleTimeout?: number;
  alwaysPreventDefaultScroll?: boolean;
}

export const SLIMSCROLL_DEFAULTS: InjectionToken<ISlimScrollOptions> = new InjectionToken('NGX_SLIMSCROLL_DEFAULTS');

export class SlimScrollOptions implements ISlimScrollOptions {
  position?: 'left' | 'right';
  barBackground?: string;
  barOpacity?: string;
  barWidth?: string;
  barBorderRadius?: string;
  barMargin?: string;
  gridBackground?: string;
  gridOpacity?: string;
  gridWidth?: string;
  gridBorderRadius?: string;
  gridMargin?: string;
  alwaysVisible?: boolean;
  visibleTimeout?: number;
  alwaysPreventDefaultScroll?: boolean;

  constructor(obj?: ISlimScrollOptions) {
    this.position = obj && obj.position ? obj.position : 'right';
    this.barBackground = obj && obj.barBackground ? obj.barBackground : '#343a40';
    this.barOpacity = obj && obj.barOpacity ? obj.barOpacity : '1';
    this.barWidth = obj && obj.barWidth ? obj.barWidth : '12';
    this.barBorderRadius = obj && obj.barBorderRadius ? obj.barBorderRadius : '5';
    this.barMargin = obj && obj.barMargin ? obj.barMargin : '0 0 0 0';
    this.gridBackground = obj && obj.gridBackground ? obj.gridBackground : '#adb5bd';
    this.gridOpacity = obj && obj.gridOpacity ? obj.gridOpacity : '1';
    this.gridWidth = obj && obj.gridWidth ? obj.gridWidth : '8';
    this.gridBorderRadius = obj && obj.gridBorderRadius ? obj.gridBorderRadius : '10';
    this.gridMargin = obj && obj.gridMargin ? obj.gridMargin : '0 0 0 0';
    this.alwaysVisible = obj && typeof obj.alwaysVisible !== 'undefined' ? obj.alwaysVisible : true;
    this.visibleTimeout = obj && obj.visibleTimeout ? obj.visibleTimeout : 1000;
    this.alwaysPreventDefaultScroll =
      obj && typeof obj.alwaysPreventDefaultScroll !== 'undefined' ? obj.alwaysPreventDefaultScroll : true;
  }

  public merge(obj?: ISlimScrollOptions): SlimScrollOptions {
    const result = new SlimScrollOptions();

    result.position = obj && obj.position ? obj.position : this.position;
    result.barBackground = obj && obj.barBackground ? obj.barBackground : this.barBackground;
    result.barOpacity = obj && obj.barOpacity ? obj.barOpacity : this.barOpacity;
    result.barWidth = obj && obj.barWidth ? obj.barWidth : this.barWidth;
    result.barBorderRadius = obj && obj.barBorderRadius ? obj.barBorderRadius : this.barBorderRadius;
    result.barMargin = obj && obj.barMargin ? obj.barMargin : this.barMargin;
    result.gridBackground = obj && obj.gridBackground ? obj.gridBackground : this.gridBackground;
    result.gridOpacity = obj && obj.gridOpacity ? obj.gridOpacity : this.gridOpacity;
    result.gridWidth = obj && obj.gridWidth ? obj.gridWidth : this.gridWidth;
    result.gridBorderRadius = obj && obj.gridBorderRadius ? obj.gridBorderRadius : this.gridBorderRadius;
    result.gridMargin = obj && obj.gridMargin ? obj.gridMargin : this.gridMargin;
    result.alwaysVisible = obj && typeof obj.alwaysVisible !== 'undefined' ? obj.alwaysVisible : this.alwaysVisible;
    result.visibleTimeout = obj && obj.visibleTimeout ? obj.visibleTimeout : this.visibleTimeout;
    result.alwaysPreventDefaultScroll =
      obj && typeof obj.alwaysPreventDefaultScroll !== 'undefined' ? obj.alwaysPreventDefaultScroll : true;

    return result;
  }
}

export interface ISlimScrollEvent {
    type: 'scrollToBottom' | 'scrollToTop' | 'scrollToPercent' | 'scrollTo' | 'recalculate';
    y?: number;
    percent?: number;
    duration?: number;
    easing?: 'linear' | 'inQuad' | 'outQuad' | 'inOutQuad' | 'inCubic' |
    'outCubic' | 'inOutCubic' | 'inQuart' | 'outQuart' | 'inOutQuart' |
    'inQuint' | 'outQuint' | 'inOutQuint';
  }
  
  export class SlimScrollEvent implements ISlimScrollEvent {
    type: 'scrollToBottom' | 'scrollToTop' | 'scrollToPercent' | 'scrollTo' | 'recalculate';
    y?: number;
    percent?: number;
    duration?: number;
    easing: 'linear' | 'inQuad' | 'outQuad' | 'inOutQuad' | 'inCubic' |
      'outCubic' | 'inOutCubic' | 'inQuart' | 'outQuart' | 'inOutQuart' |
      'inQuint' | 'outQuint' | 'inOutQuint';
  
    constructor(obj?: ISlimScrollEvent) {
      this.type = obj.type;
      this.y = obj && obj.y ? obj.y : 0;
      this.percent = obj && obj.percent ? obj.percent : 0;
      this.duration = obj && obj.duration ? obj.duration : 0;
      this.easing = obj && obj.easing ? obj.easing : 'linear';
    }
  }