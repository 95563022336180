/**
 * Translations API
 * Stores and manages catalog items translations
 *
 * OpenAPI spec version: 1.0
 * Contact: info@mersoft.am
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { LanguageReadModel } from '../model/languageReadModel';
import { ValueTupleOfInt32OfInt32 } from '../model/valueTupleOfInt32OfInt32';
import { WordTranslate } from '../model/wordTranslate';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { SaveHostLanguagesModel } from '../model/saveHostLanguagesModel';


@Injectable({
  providedIn: 'root'
})
export class TranslationService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param lang 
     * @param apiVersion The requested API version
     * @param wordTranslate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV1TranslationBulkupdateLangPost(lang: string, apiVersion: string, wordTranslate?: Array<WordTranslate>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiV1TranslationBulkupdateLangPost(lang: string, apiVersion: string, wordTranslate?: Array<WordTranslate>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiV1TranslationBulkupdateLangPost(lang: string, apiVersion: string, wordTranslate?: Array<WordTranslate>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiV1TranslationBulkupdateLangPost(lang: string, apiVersion: string, wordTranslate?: Array<WordTranslate>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (lang === null || lang === undefined) {
            throw new Error('Required parameter lang was null or undefined when calling apiV1TranslationBulkupdateLangPost.');
        }
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling apiV1TranslationBulkupdateLangPost.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (apiVersion !== undefined && apiVersion !== null) {
            queryParameters = queryParameters.set('api-version', <any>apiVersion);
        }

        let headers = this.defaultHeaders;

        // authentication (ImplicitOAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/v1/Translation/bulkupdate/${encodeURIComponent(String(lang))}`,
            wordTranslate,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param lang 
     * @param apiVersion The requested API version
     * @param includeEmpty 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV1TranslationDocumentLangGet(lang: string, apiVersion: string, includeEmpty?: boolean, observe?: 'body', reportProgress?: boolean): Observable<{ [key: string]: string; }>;
    public apiV1TranslationDocumentLangGet(lang: string, apiVersion: string, includeEmpty?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<{ [key: string]: string; }>>;
    public apiV1TranslationDocumentLangGet(lang: string, apiVersion: string, includeEmpty?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<{ [key: string]: string; }>>;
    public apiV1TranslationDocumentLangGet(lang: string, apiVersion: string, includeEmpty?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (lang === null || lang === undefined) {
            throw new Error('Required parameter lang was null or undefined when calling apiV1TranslationDocumentLangGet.');
        }
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling apiV1TranslationDocumentLangGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (includeEmpty !== undefined && includeEmpty !== null) {
            queryParameters = queryParameters.set('includeEmpty', <any>includeEmpty);
        }
        if (apiVersion !== undefined && apiVersion !== null) {
            queryParameters = queryParameters.set('api-version', <any>apiVersion);
        }

        let headers = this.defaultHeaders;

        // authentication (ImplicitOAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<{ [key: string]: string; }>(`${this.configuration.basePath}/api/v1/Translation/document/${encodeURIComponent(String(lang))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param lang 
     * @param apiVersion The requested API version
     * @param includeEmpty 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV1TranslationDocumentmodelsLangGet(lang: string, apiVersion: string, includeEmpty?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<WordTranslate>>;
    public apiV1TranslationDocumentmodelsLangGet(lang: string, apiVersion: string, includeEmpty?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WordTranslate>>>;
    public apiV1TranslationDocumentmodelsLangGet(lang: string, apiVersion: string, includeEmpty?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WordTranslate>>>;
    public apiV1TranslationDocumentmodelsLangGet(lang: string, apiVersion: string, includeEmpty?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (lang === null || lang === undefined) {
            throw new Error('Required parameter lang was null or undefined when calling apiV1TranslationDocumentmodelsLangGet.');
        }
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling apiV1TranslationDocumentmodelsLangGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (includeEmpty !== undefined && includeEmpty !== null) {
            queryParameters = queryParameters.set('includeEmpty', <any>includeEmpty);
        }
        if (apiVersion !== undefined && apiVersion !== null) {
            queryParameters = queryParameters.set('api-version', <any>apiVersion);
        }

        let headers = this.defaultHeaders;

        // authentication (ImplicitOAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<WordTranslate>>(`${this.configuration.basePath}/api/v1/Translation/documentmodels/${encodeURIComponent(String(lang))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get translated file by host and choosed language
     * 
     * @param language translation language
     * @param apiVersion The requested API version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV1TranslationHostLanguageGet(language: string, apiVersion: string, observe?: 'body', reportProgress?: boolean): Observable<{ [key: string]: string; }>;
    public apiV1TranslationHostLanguageGet(language: string, apiVersion: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<{ [key: string]: string; }>>;
    public apiV1TranslationHostLanguageGet(language: string, apiVersion: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<{ [key: string]: string; }>>;
    public apiV1TranslationHostLanguageGet(language: string, apiVersion: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (language === null || language === undefined) {
            throw new Error('Required parameter language was null or undefined when calling apiV1TranslationHostLanguageGet.');
        }
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling apiV1TranslationHostLanguageGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (apiVersion !== undefined && apiVersion !== null) {
            queryParameters = queryParameters.set('api-version', <any>apiVersion);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<{ [key: string]: string; }>(`${this.configuration.basePath}/api/v1/Translation/host/${encodeURIComponent(String(language))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * returns tranlation file by specified language(without  items and groups translations)
     * 
     * @param language translation language
     * @param apiVersion The requested API version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV1TranslationLangLanguageGet(language: string, apiVersion: string, observe?: 'body', reportProgress?: boolean): Observable<{ [key: string]: string; }>;
    public apiV1TranslationLangLanguageGet(language: string, apiVersion: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<{ [key: string]: string; }>>;
    public apiV1TranslationLangLanguageGet(language: string, apiVersion: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<{ [key: string]: string; }>>;
    public apiV1TranslationLangLanguageGet(language: string, apiVersion: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (language === null || language === undefined) {
            throw new Error('Required parameter language was null or undefined when calling apiV1TranslationLangLanguageGet.');
        }
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling apiV1TranslationLangLanguageGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (apiVersion !== undefined && apiVersion !== null) {
            queryParameters = queryParameters.set('api-version', <any>apiVersion);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<{ [key: string]: string; }>(`${this.configuration.basePath}/api/v1/Translation/lang/${encodeURIComponent(String(language))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns team choosen language flags and names(used in client) selected &#x3D; &#39;false&#39;?
     * 
     * @param apiVersion The requested API version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV1TranslationLangsAllGet(apiVersion: string, observe?: 'body', reportProgress?: boolean): Observable<Array<LanguageReadModel>>;
    public apiV1TranslationLangsAllGet(apiVersion: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LanguageReadModel>>>;
    public apiV1TranslationLangsAllGet(apiVersion: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LanguageReadModel>>>;
    public apiV1TranslationLangsAllGet(apiVersion: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling apiV1TranslationLangsAllGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (apiVersion !== undefined && apiVersion !== null) {
            queryParameters = queryParameters.set('api-version', <any>apiVersion);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<LanguageReadModel>>(`${this.configuration.basePath}/api/v1/Translation/langs/all`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param apiVersion The requested API version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV1TranslationLangsCurrentcountryGet(apiVersion: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public apiV1TranslationLangsCurrentcountryGet(apiVersion: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public apiV1TranslationLangsCurrentcountryGet(apiVersion: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public apiV1TranslationLangsCurrentcountryGet(apiVersion: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling apiV1TranslationLangsCurrentcountryGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (apiVersion !== undefined && apiVersion !== null) {
            queryParameters = queryParameters.set('api-version', <any>apiVersion);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<string>(`${this.configuration.basePath}/api/v1/Translation/langs/currentcountry`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retruns factory language flags and flags short name(by default set eng)
     * 
     * @param apiVersion The requested API version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV1TranslationLangsDefallGet(apiVersion: string, loadUrl: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<LanguageReadModel>>;
    public apiV1TranslationLangsDefallGet(apiVersion: string, loadUrl: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LanguageReadModel>>>;
    public apiV1TranslationLangsDefallGet(apiVersion: string, loadUrl: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LanguageReadModel>>>;
    public apiV1TranslationLangsDefallGet(apiVersion: string, loadUrl: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling apiV1TranslationLangsDefallGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (apiVersion !== undefined && apiVersion !== null) {
            queryParameters = queryParameters.set('api-version', <any>apiVersion);
        }
        if (loadUrl !== undefined && loadUrl !== null) {
            queryParameters = queryParameters.set('loadUrl', <any>loadUrl);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<LanguageReadModel>>(`${this.configuration.basePath}/api/v1/Translation/langs/defall`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param apiVersion The requested API version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV1TranslationLangsRefreshPost(apiVersion: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiV1TranslationLangsRefreshPost(apiVersion: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiV1TranslationLangsRefreshPost(apiVersion: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiV1TranslationLangsRefreshPost(apiVersion: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling apiV1TranslationLangsRefreshPost.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (apiVersion !== undefined && apiVersion !== null) {
            queryParameters = queryParameters.set('api-version', <any>apiVersion);
        }

        let headers = this.defaultHeaders;

        // authentication (ImplicitOAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<boolean>(`${this.configuration.basePath}/api/v1/Translation/langs/refresh`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Saves team choosed languages and returns list of choosed language short names
     * 
     * @param apiVersion The requested API version
     * @param requestBody 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV1TranslationLangsSaveteamPost(apiVersion: string, requestBody?: SaveHostLanguagesModel, observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public apiV1TranslationLangsSaveteamPost(apiVersion: string, requestBody?: SaveHostLanguagesModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public apiV1TranslationLangsSaveteamPost(apiVersion: string, requestBody?: SaveHostLanguagesModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public apiV1TranslationLangsSaveteamPost(apiVersion: string, requestBody?: SaveHostLanguagesModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling apiV1TranslationLangsSaveteamPost.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (apiVersion !== undefined && apiVersion !== null) {
            queryParameters = queryParameters.set('api-version', <any>apiVersion);
        }

        let headers = this.defaultHeaders;

        // authentication (ImplicitOAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<string>>(`${this.configuration.basePath}/api/v1/Translation/langs/saveteam`,
            requestBody,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns all  language flags and short names, for choosed languages sets \&quot;selected &#x3D; true\&quot; otherwise false
     * 
     * @param apiVersion The requested API version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV1TranslationLangsTeamallGet(apiVersion: string, loadUrl: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<LanguageReadModel>>;
    public apiV1TranslationLangsTeamallGet(apiVersion: string, loadUrl: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LanguageReadModel>>>;
    public apiV1TranslationLangsTeamallGet(apiVersion: string, loadUrl: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LanguageReadModel>>>;
    public apiV1TranslationLangsTeamallGet(apiVersion: string, loadUrl: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling apiV1TranslationLangsTeamallGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (apiVersion !== undefined && apiVersion !== null) {
            queryParameters = queryParameters.set('api-version', <any>apiVersion);
        }
        if (loadUrl !== undefined && loadUrl !== null) {
            queryParameters = queryParameters.set('loadUrl', <any>loadUrl);
        }

        let headers = this.defaultHeaders;

        // authentication (ImplicitOAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<LanguageReadModel>>(`${this.configuration.basePath}/api/v1/Translation/langs/teamall`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns team translation file by choosed language
     * 
     * @param hostLanguage translate language
     * @param apiVersion The requested API version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV1TranslationLanguageHostHostLanguageGet(hostLanguage: string, apiVersion: string, observe?: 'body', reportProgress?: boolean): Observable<{ [key: string]: string; }>;
    public apiV1TranslationLanguageHostHostLanguageGet(hostLanguage: string, apiVersion: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<{ [key: string]: string; }>>;
    public apiV1TranslationLanguageHostHostLanguageGet(hostLanguage: string, apiVersion: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<{ [key: string]: string; }>>;
    public apiV1TranslationLanguageHostHostLanguageGet(hostLanguage: string, apiVersion: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (hostLanguage === null || hostLanguage === undefined) {
            throw new Error('Required parameter hostLanguage was null or undefined when calling apiV1TranslationLanguageHostHostLanguageGet.');
        }
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling apiV1TranslationLanguageHostHostLanguageGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (apiVersion !== undefined && apiVersion !== null) {
            queryParameters = queryParameters.set('api-version', <any>apiVersion);
        }

        let headers = this.defaultHeaders;

        // authentication (ImplicitOAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<{ [key: string]: string; }>(`${this.configuration.basePath}/api/v1/Translation/language/host/${encodeURIComponent(String(hostLanguage))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns list of WordTranslate model (with keys and values) by sepcified language
     * 
     * @param language 
     * @param apiVersion The requested API version
     * @param requestBody 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV1TranslationLanguageLanguagePost(language: string, apiVersion: string, requestBody?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<WordTranslate>>;
    public apiV1TranslationLanguageLanguagePost(language: string, apiVersion: string, requestBody?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WordTranslate>>>;
    public apiV1TranslationLanguageLanguagePost(language: string, apiVersion: string, requestBody?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WordTranslate>>>;
    public apiV1TranslationLanguageLanguagePost(language: string, apiVersion: string, requestBody?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (language === null || language === undefined) {
            throw new Error('Required parameter language was null or undefined when calling apiV1TranslationLanguageLanguagePost.');
        }
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling apiV1TranslationLanguageLanguagePost.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (apiVersion !== undefined && apiVersion !== null) {
            queryParameters = queryParameters.set('api-version', <any>apiVersion);
        }

        let headers = this.defaultHeaders;

        // authentication (ImplicitOAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<WordTranslate>>(`${this.configuration.basePath}/api/v1/Translation/language/${encodeURIComponent(String(language))}`,
            requestBody,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param language 
     * @param team 
     * @param apiVersion The requested API version
     * @param requestBody 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV1TranslationLanguageLanguageTeamPost(language: string, team: string, apiVersion: string, requestBody?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<WordTranslate>>;
    public apiV1TranslationLanguageLanguageTeamPost(language: string, team: string, apiVersion: string, requestBody?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WordTranslate>>>;
    public apiV1TranslationLanguageLanguageTeamPost(language: string, team: string, apiVersion: string, requestBody?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WordTranslate>>>;
    public apiV1TranslationLanguageLanguageTeamPost(language: string, team: string, apiVersion: string, requestBody?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (language === null || language === undefined) {
            throw new Error('Required parameter language was null or undefined when calling apiV1TranslationLanguageLanguageTeamPost.');
        }
        if (team === null || team === undefined) {
            throw new Error('Required parameter team was null or undefined when calling apiV1TranslationLanguageLanguageTeamPost.');
        }
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling apiV1TranslationLanguageLanguageTeamPost.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (apiVersion !== undefined && apiVersion !== null) {
            queryParameters = queryParameters.set('api-version', <any>apiVersion);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<WordTranslate>>(`${this.configuration.basePath}/api/v1/Translation/language/${encodeURIComponent(String(language))}/${encodeURIComponent(String(team))}`,
            requestBody,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param lang 
     * @param apiVersion The requested API version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV1TranslationPercentLangGet(lang: string, apiVersion: string, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public apiV1TranslationPercentLangGet(lang: string, apiVersion: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public apiV1TranslationPercentLangGet(lang: string, apiVersion: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public apiV1TranslationPercentLangGet(lang: string, apiVersion: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (lang === null || lang === undefined) {
            throw new Error('Required parameter lang was null or undefined when calling apiV1TranslationPercentLangGet.');
        }
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling apiV1TranslationPercentLangGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (apiVersion !== undefined && apiVersion !== null) {
            queryParameters = queryParameters.set('api-version', <any>apiVersion);
        }

        let headers = this.defaultHeaders;

        // authentication (ImplicitOAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<number>(`${this.configuration.basePath}/api/v1/Translation/percent/${encodeURIComponent(String(lang))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param langs 
     * @param apiVersion The requested API version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV1TranslationPercentsLangsGet(langs: string, apiVersion: string, observe?: 'body', reportProgress?: boolean): Observable<ValueTupleOfInt32OfInt32>;
    public apiV1TranslationPercentsLangsGet(langs: string, apiVersion: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ValueTupleOfInt32OfInt32>>;
    public apiV1TranslationPercentsLangsGet(langs: string, apiVersion: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ValueTupleOfInt32OfInt32>>;
    public apiV1TranslationPercentsLangsGet(langs: string, apiVersion: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (langs === null || langs === undefined) {
            throw new Error('Required parameter langs was null or undefined when calling apiV1TranslationPercentsLangsGet.');
        }
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling apiV1TranslationPercentsLangsGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (apiVersion !== undefined && apiVersion !== null) {
            queryParameters = queryParameters.set('api-version', <any>apiVersion);
        }

        let headers = this.defaultHeaders;

        // authentication (ImplicitOAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ValueTupleOfInt32OfInt32>(`${this.configuration.basePath}/api/v1/Translation/percents/${encodeURIComponent(String(langs))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param language 
     * @param apiVersion The requested API version
     * @param token 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV1TranslationScannerLanguageGet(language: string, apiVersion: string, token?: string, observe?: 'body', reportProgress?: boolean): Observable<{ [key: string]: string; }>;
    public apiV1TranslationScannerLanguageGet(language: string, apiVersion: string, token?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<{ [key: string]: string; }>>;
    public apiV1TranslationScannerLanguageGet(language: string, apiVersion: string, token?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<{ [key: string]: string; }>>;
    public apiV1TranslationScannerLanguageGet(language: string, apiVersion: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (language === null || language === undefined) {
            throw new Error('Required parameter language was null or undefined when calling apiV1TranslationScannerLanguageGet.');
        }
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling apiV1TranslationScannerLanguageGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }
        if (apiVersion !== undefined && apiVersion !== null) {
            queryParameters = queryParameters.set('api-version', <any>apiVersion);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<{ [key: string]: string; }>(`${this.configuration.basePath}/api/v1/Translation/scanner/${encodeURIComponent(String(language))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns searched words(by partner selected language) keys
     * 
     * @param word 
     * @param language 
     * @param apiVersion The requested API version
     * @param requestBody 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV1TranslationWordWordLanguageLanguagePost(word: string, language: string, apiVersion: string, requestBody?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public apiV1TranslationWordWordLanguageLanguagePost(word: string, language: string, apiVersion: string, requestBody?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public apiV1TranslationWordWordLanguageLanguagePost(word: string, language: string, apiVersion: string, requestBody?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public apiV1TranslationWordWordLanguageLanguagePost(word: string, language: string, apiVersion: string, requestBody?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (word === null || word === undefined) {
            throw new Error('Required parameter word was null or undefined when calling apiV1TranslationWordWordLanguageLanguagePost.');
        }
        if (language === null || language === undefined) {
            throw new Error('Required parameter language was null or undefined when calling apiV1TranslationWordWordLanguageLanguagePost.');
        }
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling apiV1TranslationWordWordLanguageLanguagePost.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (apiVersion !== undefined && apiVersion !== null) {
            queryParameters = queryParameters.set('api-version', <any>apiVersion);
        }

        let headers = this.defaultHeaders;

        // authentication (ImplicitOAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<string>>(`${this.configuration.basePath}/api/v1/Translation/word/${encodeURIComponent(String(word))}/language/${encodeURIComponent(String(language))}`,
            requestBody,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param word 
     * @param searchKey 
     * @param apiVersion The requested API version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV1TranslationWordWordSearchKeySearchKeyGet(word: string, searchKey: string, apiVersion: string, observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public apiV1TranslationWordWordSearchKeySearchKeyGet(word: string, searchKey: string, apiVersion: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public apiV1TranslationWordWordSearchKeySearchKeyGet(word: string, searchKey: string, apiVersion: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public apiV1TranslationWordWordSearchKeySearchKeyGet(word: string, searchKey: string, apiVersion: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (word === null || word === undefined) {
            throw new Error('Required parameter word was null or undefined when calling apiV1TranslationWordWordSearchKeySearchKeyGet.');
        }
        if (searchKey === null || searchKey === undefined) {
            throw new Error('Required parameter searchKey was null or undefined when calling apiV1TranslationWordWordSearchKeySearchKeyGet.');
        }
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling apiV1TranslationWordWordSearchKeySearchKeyGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (apiVersion !== undefined && apiVersion !== null) {
            queryParameters = queryParameters.set('api-version', <any>apiVersion);
        }

        let headers = this.defaultHeaders;

        // authentication (ImplicitOAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<string>>(`${this.configuration.basePath}/api/v1/Translation/word/${encodeURIComponent(String(word))}/searchKey/${encodeURIComponent(String(searchKey))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
