import { Injectable } from '@angular/core';
import { tap } from 'rxjs/internal/operators/tap';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class ConfigService {
    private config: Object = null;
    constructor(private http: HttpClient) { }

    public get(key: any) {
        return this.config[key];
    }

    public load(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.get('manifest.json').pipe(
                tap(
                    (res) => resolve(true),
                    (err) => console.error(err)
                )
            ).subscribe((envResponse) => {
                this.config = envResponse;
            });
        }).catch(err => {});
    }
}
