import { Injectable, Inject } from '@angular/core';
import { LOCAL_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import { AuthService } from 'src/applazy/services/auth.service';
import { User } from '../models/User';
import { StorageKeys } from './constant.service';
@Injectable()
export class PaginationInfoService {
    private _paginatioModels: IPaginationModel[];
    // private _currentModel: IPaginationModel;
    private _name: string;
    get _currentModel(): IPaginationModel {
        let index = this._paginatioModels.findIndex(x => x.name === this._name);
        if (index === -1) {
            index = this._paginatioModels.length;
            const model = new PaginationModel(this._name);
            model.name = this._name;
            this._paginatioModels.push(model);
        }
        return this._paginatioModels[index];
    }

    set _currentModel(value: IPaginationModel) {
        value.name = this._name;
        const index = this._paginatioModels.findIndex(x => x.name === this._name);
        if (index === -1) {
            this._paginatioModels.push(value);
        } else {
            this._paginatioModels[index] = value;
        }
    }

    constructor(
        @Inject(LOCAL_STORAGE) private webStorageService: WebStorageService,
    ) {
        this._name = '';
        this._paginatioModels = [];
    }

    private getInfo(name: string) {
        const value = this.webStorageService.get(this.getKey(name));
        if (!value || value === null || value === undefined) {
            this._currentModel = new PaginationModel(name);
            return;
        }
        this._currentModel = <IPaginationModel>value;
    }

    addPage(page: number): PaginationInfoService {
        this._currentModel.page = page;
        if (this._currentModel.pageRowSize === 1) {
            this._currentModel.pageRow = page;
        } else {
            const col = page;
            const row = Number.parseInt((col / this._currentModel.pageRowSize).toString());
            const pageRowIndex = row;
            this._currentModel.pageRow = pageRowIndex;
        }
        return this;
    }

    resetPage(): PaginationInfoService {
        this._currentModel.page = 0;
        this._currentModel.pageRow = 0;
        return this;
    }

    addScroll(scrollTo: number): PaginationInfoService {
        this._currentModel.scrollTo = scrollTo;
        return this;
    }

    resetScroll(): PaginationInfoService {
        this._currentModel.scrollTo = 0;
        return this;
    }

    addPageSize(pageSize: number, pageRowSize: number): PaginationInfoService {
        this._currentModel.pageSize = pageSize;
        this._currentModel.pageRowSize = pageRowSize;
        return this;
    }

    addTotalCount(totalCount: number): PaginationInfoService {
        this._currentModel.totalCount = totalCount;
        return this;
    }

    addItemHeightAndWidth(itemHeight: number, itemWidth: number): PaginationInfoService {
        this._currentModel.itemHeight = itemHeight;
        this._currentModel.itemWidth = itemWidth;
        return this;
    }

    addKey(key: string, keyIndex: number): PaginationInfoService {
        this._currentModel.key = key;
        const pageIndex = Number.parseInt((keyIndex / this._currentModel.pageSize).toString());

        if (this._currentModel.pageRowSize === 1) {
            if (pageIndex > this._currentModel.page) {
                this._currentModel.scrollTo = this._currentModel.scrollTo + (pageIndex - this._currentModel.page) * this._currentModel.itemHeight;
                this._currentModel.page = pageIndex;
                this._currentModel.pageRow = pageIndex;
            }
        } else {
            const col = keyIndex;
            const row = Number.parseInt((col / this._currentModel.pageRowSize).toString());
            const pageRowIndex = row;
            if (pageRowIndex > this._currentModel.pageRow) {
                this._currentModel.scrollTo = this._currentModel.scrollTo + (pageRowIndex - this._currentModel.pageRow) * this._currentModel.itemHeight;
                this._currentModel.pageRow = pageRowIndex;
                this._currentModel.page = pageIndex;
            }
        }
        this.savePaginationInfo(this._currentModel.name);
        return this;
    }

    scrollToTheEnd() {
        const page = Number.parseInt((this._currentModel.totalCount / this._currentModel.pageSize).toString());
        let pageRow = 0;
        if (this._currentModel.pageRowSize === 1) {
            this._currentModel.scrollTo = this._currentModel.itemHeight * this._currentModel.totalCount;
            pageRow = page;
        } else {
            this._currentModel.scrollTo = this._currentModel.itemHeight * this._currentModel.totalCount / this._currentModel.pageRowSize;
            pageRow = Number.parseInt((this._currentModel.totalCount / this._currentModel.pageRowSize).toString());
        }
        this._currentModel.page = page;
        this._currentModel.pageRow = pageRow;
        this.savePaginationInfo(this._currentModel.name);
    }

    scrollToTheTop() {
        this._currentModel.scrollTo = 0;
        this._currentModel.page = 0;
        this._currentModel.pageRow = 0;
        this.savePaginationInfo(this._currentModel.name);
    }

    savePaginationInfo(name: string) {
        this._name = name;
        this._currentModel.name = name;
        this.webStorageService.set(this.getKey(name), this._currentModel);
    }

    getPaginationInfo(name: string): IPaginationModel {
        this._name = name;
        this.getInfo(this._name);
        if (this._currentModel.name !== name) {
            this.removePaginationInfo();
            this._currentModel.name = name;
        }
        return this._currentModel;
    }

    removePaginationInfo() {
        this.webStorageService.remove(this.getKey(this._currentModel.name));
        const index = this._paginatioModels.findIndex(x => x.name === this._currentModel.name);
        if (index !== -1) {
            this._paginatioModels.splice(index, 1);
        }
    }

    clear() {
        if (this._paginatioModels && this._paginatioModels.length > 0) {
            this._paginatioModels.forEach(p => {
                this.webStorageService.remove(this.getKey(p.name));
            });
            this._paginatioModels = [];
        }
    }

    private getKey(name: string): string {
        const key = User.sub + '_' + StorageKeys.SCROLL_DATA_NAME + '_' + name;
        return key;
    }
}

export interface IPaginationModel {
    page: number;
    pageRow: number;
    pageSize: number;
    pageRowSize: number;
    totalCount: number;
    scrollTo?: number;
    itemHeight: number;
    itemWidth: number;
    name: string;
    key: string;
}

export class PaginationModel implements IPaginationModel {
    page: number;
    pageRow: number;
    pageSize: number;
    pageRowSize: number;
    totalCount: number;
    scrollTo?: number;
    itemHeight: number;
    itemWidth: number;
    name: string;
    key: string;
    constructor(name: string) {
        this.name = name;
        this.page = 0;
        this.pageRow = 0;
        this.pageSize = 10;
        this.pageRowSize = 1;
        this.totalCount = 0;
        this.scrollTo = 0;
        this.itemHeight = 0;
        this.itemWidth = 0;
        this.key = '';
    }
}
