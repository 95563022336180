import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeValue } from '@angular/platform-browser';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
export const ENTITIES_REGEX = /&([a-z0-9]+|#[0-9]{1,6}|#x[0-9a-fA-F]{1,6});/ig;
const createDOMPurify = require('dompurify');

@Injectable()
export class XssSanitizerService {
    private readonly _domSanitizer: DomSanitizer;
    private readonly _domPurify: any;
    private _http: HttpClient;
    loadedHtmlEntities: Array<HtmlEntityDecodedModel> = [];
    constructor(domSanitizer: DomSanitizer) {
        this._domSanitizer = domSanitizer;
        this._domPurify = createDOMPurify(window);
    }

    setHttpClient(http: HttpClient): XssSanitizerService {
        this._http = http;
        return this;
    }

    getHtmlDecodedText(text: string): Observable<HtmlEntityDecodedModel> {
        const url = environment.globalizationUrl + '/graphql';
        const postbody = {
            operationName: null,
            query: `{htmlentitytext(text:"${text}"){originValue,decodedValue}}`,
            variables: null
        };
        return this._http.post<any>(url, postbody).pipe(map(res => {
            return <HtmlEntityDecodedModel>res.data.htmlentitytext;
        }, () => {
            return new HtmlEntityDecodedModel();
        }
        ));
    }
    getHtmlentitytextdecodedfields(text: string): Observable<HtmlEntityDecodedTextInfo> {
        let res = new HtmlEntityDecodedTextInfo();
        res.entityText = text;
        const keys: string[] = text.match(ENTITIES_REGEX);
        if (!keys || keys.length === 0) {
            return from([res]);
        }
        const requestText = keys.join('');
        let existAll = true;
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const index = this.loadedHtmlEntities.findIndex(x => x.originValue === key);
            if (index === -1) {
                existAll = false;
                break;
            } else {
                res.entities.push(new HtmlEntityDecodedModel().setValue(key, this.loadedHtmlEntities[index].decodedValue));
            }
        }
        if (existAll) {
            return from([res]);
        }

        const url = environment.globalizationUrl + '/graphql';
        const postbody = {
            operationName: null,
            query: `{htmlentitytextdecodedfields(text:"${requestText}"){entityText,entities{originValue,decodedValue,}}}`,
            variables: null
        };
        return this._http.post<any>(url, postbody).pipe(map(response => {
            res = <HtmlEntityDecodedTextInfo>response.data.htmlentitytextdecodedfields;
            if (res && res.entities.length > 0) {
                res.entities.forEach(entity => this.loadedHtmlEntities.push(entity));
            }
            return res;
        },
            err => {
                return res;
            }
        ));
    }
    public getFromLoadedEntities(text: string): string {
        const keys: string[] = text.match(ENTITIES_REGEX);
        if (!keys || keys.length === 0) {
            return text;
        }
        while (
            this.loadedHtmlEntities.filter(x => text.indexOf(x.originValue) !== -1) &&
            this.loadedHtmlEntities.filter(x => text.indexOf(x.originValue) !== -1).length > 0) {
            this.loadedHtmlEntities.forEach(dti => text = text.replace(dti.originValue, dti.decodedValue));
        }
        while (
            keys.filter(x => text.indexOf(x) !== -1) &&
            keys.filter(x => text.indexOf(x) !== -1).length > 0) {
            keys.forEach(x => text = text.replace(x, ''));
        }
        return text;
    }

    public customDecoder(s: string): string {
        const custom = this._customDecoder(s);
        const clean = this._domPurify.sanitize(custom);
        const trustedClean = this._domSanitizer.bypassSecurityTrustHtml(clean);
        const safeValue = trustedClean as SafeValue;
        if (safeValue) {
            return (<any>safeValue).changingThisBreaksApplicationSecurity;
        }
        return clean;
    }

    private _customDecoder(s: string): string {
        let escaped = s;
        escaped = this._replaceLoop(escaped, '|PLS|', '+');
        escaped = this._replaceLoop(escaped, '|EQLS|', '=');
        escaped = this._replaceLoop(escaped, '|MNS|', '-');
        escaped = this._replaceLoop(escaped, '|STR|', '*');
        escaped = this._replaceLoop(escaped, '|SHRP|', '#');
        escaped = this._replaceLoop(escaped, '|DLR|', '$');
        escaped = this._replaceLoop(escaped, '|AT|', '@');
        escaped = this._replaceLoop(escaped, '|LSCP|', '(');
        escaped = this._replaceLoop(escaped, '|RSCP|', ')');
        escaped = this._replaceLoop(escaped, '|LBRC|', '{');
        escaped = this._replaceLoop(escaped, '|RBRC|', '}');
        escaped = this._replaceLoop(escaped, '|LSBRC|', '[');
        escaped = this._replaceLoop(escaped, '|RSBRC|', ']');
        escaped = this._replaceLoop(escaped, '|LRRW|', '<');
        escaped = this._replaceLoop(escaped, '|RRRW|', '>');
        escaped = this._replaceLoop(escaped, '|QWT|', '"');
        escaped = this._replaceLoop(escaped, '|SQWT|', '\'');
        escaped = this._replaceLoop(escaped, '|ND|', '&');
        escaped = this._replaceLoop(escaped, '|SMCLN|', ';');
        escaped = this._replaceLoop(escaped, '|CLN|', ',');
        escaped = this._replaceLoop(escaped, '|NDRLN|', '_');
        escaped = this._replaceLoop(escaped, '|PRSNT|', '%');
        escaped = this._replaceLoop(escaped, '|XCLM|', '!');
        escaped = this._replaceLoop(escaped, '|GRVS|', '`');
        escaped = this._replaceLoop(escaped, '|CRT|', '^');
        escaped = this._replaceLoop(escaped, '|PCTSH|', ':');
        return escaped;
    }

    private _replaceLoop(stringToReplace: string, replaceMask: string, replaceWith: string): string {
        let replacedString = stringToReplace;
        if (replacedString === undefined) {
            return;
        }
        while (replacedString.includes(replaceMask)) {
            replacedString = replacedString.replace(replaceMask, replaceWith);
        }
        return replacedString;
    }
}
export class HtmlEntityDecodedModel {
    originValue: string;
    decodedValue: string;
    constructor() {
        this.originValue = '';
        this.decodedValue = '';
    }

    setValue(
        originValue: string,
        decodedValue: string
    ): HtmlEntityDecodedModel {
        this.originValue = originValue;
        this.decodedValue = decodedValue;
        return this;
    }
}
export class HtmlEntityDecodedTextInfo {
    entityText: string;
    entities: HtmlEntityDecodedModel[];
    constructor() {
        this.entityText = '';
        this.entities = [];
    }
}
