import { Injectable } from '@angular/core';
import { MarketPlaceService } from 'src/applazy/services/marketplace.service';
import { DeliveryStoreBaseModel } from '../../models/orderTypeSetting';

@Injectable()
export class StoreMarketPlaceOrderService {
    private readonly _marketPlaceService: MarketPlaceService;
    private readonly _apiVersion = '1.0';
    constructor(marketPlaceService: MarketPlaceService) {
        this._marketPlaceService = marketPlaceService;
    }

    async getDeliveryApplications(model: DeliveryStoreBaseModel, userId: string): Promise<{ id: string, name: string, logo: string }[]> {
        const models: { id: string, name: string, logo: string }[] = [];
        let deliveryApplications = await this._marketPlaceService.getActiveDeliveryApplications(this._apiVersion, userId).toPromise();
        if (deliveryApplications && deliveryApplications.length > 0) {
            deliveryApplications = deliveryApplications.filter(d => d.state === 'Active' && d.teamState === 'Active') || [];
            deliveryApplications.forEach(d => {
                models.push({ id: d.id, name: d.name, logo: d.logo });
            });
        }
        if (model.isActiveServiceFeeFix) {
            models.push({ id: undefined, name: 'base', logo: 'assets/images/svg/generalicons/notifications/delivery.svg' });
        }
        return models;
    }
}
