import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LanguageReadModel, TranslationService } from 'src/applazy/generatedmodules/translation/api';
import { SaveHostLanguagesModel } from 'src/applazy/generatedmodules/translation/api/model/saveHostLanguagesModel';
import { BaseTranslationDataService } from 'src/applazy/services/basetranslationdata.service';

@Injectable({
    providedIn: 'root'
})
export class PartnerTranslationDataService implements BaseTranslationDataService {
    constructor(
        private translationService: TranslationService
    ) {
    }
    getAuthLanguages(loadUrl: boolean): Observable<LanguageReadModel[]> {
        return this.translationService.apiV1TranslationLangsTeamallGet('1.0', loadUrl);
    }
    saveAuthLanguages(languages: string[]) {
        return this.translationService.apiV1TranslationLangsSaveteamPost('1.0', new SaveHostLanguagesModel(languages));
    }
    getWordByKey(keys: string[], language: string) {
        return this.translationService.apiV1TranslationLanguageLanguagePost(language, '1.0', keys);
    }
}
