import { ApiModule as TranslationApiModule, BASE_PATH as API_TRANSLATION_URL, DirectTranslationService, TranslationService } from 'src/applazy/generatedmodules/translation/api';

import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ApplicationInsightsModule } from '@markpieszak/ng-application-insights';
import { environment } from 'src/environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './approuting.module';
import { HomeComponent } from './main/home/home.component';
import { ConfigService } from './services/config.service';
import { HttpLoaderFactory, SharedModule } from './shared/shared.module';
import { ToastrModule } from 'ngx-toastr';
import { BrowserModule } from '@angular/platform-browser';
import { AuthService } from './services/auth.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { StorageService } from './services/storage.service';
import { TranslationLanguageService } from './services/translationlanguage.service';
import { AuthGuardHome } from './guards/auth-guard-home.service';
import { AuthGuardMain } from './guards/auth-guard-main.service';
import { AuthGuard } from './guards/auth-guard.service';
import { CanDeactivateGuard } from './guards/can-deactivate-guard.service';
import { UnAuthGuard } from './guards/unauth-guard.service';
import { RedirectAdminGuard } from './guards/redirect-admin.guard';
import { ErrorsService } from './shared/services/errors.service';
import { MessageService } from './shared/components/messagebox/services/message.service';
import { LoaderService } from './services/loader.service';
import { PaginationTitleService } from './shared/services/paginationtitle.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PaginationInfoService } from './shared/services/paginationinfo.service';
import { PaymentSystemsService } from './services/paymentSystems.service';
import { ImageCloudDataService } from './services/imageclouddata.service';
import { IconService } from './services/icon.service';
import { MarketPlaceService } from './services/marketplace.service';
import { SignalRNotificationClient } from './services/notification.signalR.service';
import { NavigationMenuService } from './shared/components/navigation/services/navigation-menu.service';
import { CompanyTypeSubscriptionsService } from './services/companytype.subscriptions.service';
import { CompanyTypeService } from './services/companytype/companytype.service';
import { CompanyTypeDataService } from './services/companytype/companytypedata.service';

export function initializeApp(appConfig: ConfigService) {
  const promise = appConfig.load().then(() => {
    const config: Microsoft.ApplicationInsights.IConfig = {
      instrumentationKey: environment.appInsightsInstrumentationKey,
      enableCorsCorrelation: false,
      disableTelemetry: !environment.production
    };
    appInsights.downloadAndSetup(config);
  });
  return () => promise;
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent
  ],
  exports: [],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (HttpLoaderFactory),
        deps: [StorageService, TranslationService, DirectTranslationService, AuthService]
      }
    }),
    BrowserModule,
    BrowserAnimationsModule,
    TranslationApiModule,
    AppRoutingModule,
    ToastrModule.forRoot(
      {
        closeButton: true,
        progressBar: true,
      },

    ),
    SharedModule,
    ApplicationInsightsModule.forRoot({
      instrumentationKey: environment.appInsightsInstrumentationKey,
      enableCorsCorrelation: true,
      disableTelemetry: !environment.production
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [

    { provide: API_TRANSLATION_URL, useValue: environment.translationUrl },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [ConfigService], multi: true },
    MessageService,
    TranslationLanguageService,
    CompanyTypeSubscriptionsService,
    StorageService,
    SignalRNotificationClient,
    CompanyTypeService,
    CompanyTypeDataService,
    PaymentSystemsService,
    MarketPlaceService,
    AuthService,
    ConfigService,
    AuthGuardHome,
    AuthGuardMain,
    RedirectAdminGuard,
    AuthGuard,
    CanDeactivateGuard,
    UnAuthGuard,
    LoaderService,
    ErrorsService,
    PaginationTitleService,
    PaginationInfoService,
    IconService,
    ImageCloudDataService,
    NavigationMenuService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() { }
}
