import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class GlobalizationService {
  allCurrencies: Array<Currency> = [];
  allCountries: Array<CountryInfoModel> = [];
  currentCountry = '';
  private _loadedCountriesInfo: Array<{ code: string, country: CountryInfoModel }> = [];

  constructor(
    private http: HttpClient
  ) {
  }

  getAllCurrencies(): Observable<boolean> {
    if (this.allCurrencies && this.allCurrencies.length > 0) {
      return from([true]);
    }
    const url = environment.globalizationUrl + '/graphql';
    const postbody = {
      operationName: null,
      query: '{ currencies(first:300) { items{ alphabeticCode, numericCode, countryCode, currencyName }}}',
      variables: null
    };
    if (this.allCurrencies && this.allCurrencies.length > 0) {
      return from([true]);
    }
    return this.http.post(url, postbody).pipe(map((response_: any) => {
      this.allCurrencies = <Currency[]>response_.data.currencies.items;
      return true;
    }, err => {
      return false;
    }));
  }

  getCountriesFullInfo(): Observable<CountryInfoModel[]> {
    const url = environment.globalizationUrl + '/graphql';
    const postbody = {
      operationName: null,
      query: '{countries(first: 300) {items { name{common}, callingCode, cca2, currencies{ alphabeticCode, numericCode, countryCode }}}}',
      variables: null
    };
    if (this.allCountries && this.allCountries.length > 0) {
      return from([this.allCountries]);
    }
    return this.http.post<any>(url, postbody).pipe(map(res => {
      this.allCountries = <CountryInfoModel[]>res.data.countries.items;
      return this.allCountries;
    }, err => {
      return [];
    }));
  }

  getCountryInfo(countryCode: string): Observable<CountryInfoModel> {
    if (this._loadedCountriesInfo.findIndex(x => x.code.toLowerCase() === countryCode.toLowerCase()) !== -1) {
      return from([this._loadedCountriesInfo.find(x => x.code.toLowerCase() === countryCode.toLowerCase()).country]);
    }
    if (this.allCountries && this.allCountries.length > 0) {
      if (this.allCountries.findIndex(x => x.cca2.toLowerCase() === countryCode.toLowerCase()) !== -1) {
        const c = this.allCountries.find(x => x.cca2.toLowerCase() === countryCode.toLowerCase());
        this._loadedCountriesInfo.push({ code: countryCode.toLowerCase(), country: c });
        return from([c]);
      }
    }
    const url = environment.globalizationUrl + '/graphql';
    const postbody = {
      operationName: null,
      query: `{ country(cca2: "${countryCode}") { cca2, callingCode, name{ common }, currencies { numericCode, alphabeticCode, minorUnit } languages { iso639_1 }, timezones{id, text, offset, }}}`,
      variables: null
    };
    return this.http.post<any>(url, postbody).pipe(map(res => {
      const country = <CountryInfoModel>res.data.country;
      this._loadedCountriesInfo.push({ code: countryCode, country: country });
      return country;
    },
      err => {
        return <CountryInfoModel>{};
      }
    ));
  }

  getSymbolByCode(currencyCode: string): string {
    const currency = this.allCurrencies.find(x => x.numericCode === currencyCode);
    return currency ? currency.alphabeticCode : '';
  }

  getSymbolByCountryCode(countryCode: string): string {
    const currency = this.allCurrencies.find(x => x.countryCode.toLowerCase() === countryCode.toLowerCase());
    return currency ? currency.alphabeticCode : '';
  }

  getCountryCodeByNumericCode(numericCode: string) {
    const currency = this.allCurrencies.find(x => x.numericCode === numericCode);
    return currency ? currency.countryCode : '';
  }

  getCountryPhoneCodes(): Observable<any> {
    const url = environment.globalizationUrl + '/graphql';
    const postbody = {
      operationName: null,
      query: '{countries(first: 300){items{ name{common}, callingCode,cca2}}}',
      variables: null
    };
    return this.http.post<any>(url, postbody);
  }

  getCurrentCountry(): Observable<string> {
    if (this.currentCountry) {
      return from([this.currentCountry]);
    }
    const url = environment.globalizationUrl + '/api/location/CurrentCountry';
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    const requestOptions: Object = {
      /* other options here */
      responseType: 'text'
    };
    return this.http.get<string>(url, requestOptions).pipe(map(res => {
      this.currentCountry = res;
      return this.currentCountry;
    }, err => {
      return '';
    }));
  }

}
export class CountryInfoModel {
  name: CountryName;
  callingCode: string[];
  cca2: string;
  currencies: Currency[];
  timezones: TimeZone[];
}
export class CountryName {
  common: string;
}
export class Currency {
  alphabeticCode: string;
  numericCode: string;
  countryCode: string;
  currencyName: string;
}

export class TimeZone {
  id: string;
  abbreviation: string;
  offset: number;
  daylightSavingTime: boolean;
  text: string;
  uTC: string;
}
