import { HttpClient } from '@angular/common/http';
import { EventEmitter } from '@angular/core';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigService } from 'src/applazy/services/config.service';
import { environment } from 'src/environments/environment';
import { ConstantService } from './constant.service';

@Injectable({ providedIn: 'root' })
export class UserLocationService {
  private readonly _httpClient: HttpClient;
  private readonly _baseUrl: string;
  private readonly _apiKey: string;
  private _currentCountry: string;
  onSuccessCallback: EventEmitter<{ latitude: number, logitude: number }> = new EventEmitter<{ latitude: number, logitude: number }>();
  onFailCallback: EventEmitter<{ code: number, message: string }> = new EventEmitter<{ code: number, message: string }>();
  constructor(
    httpClient: HttpClient,
    configurationService: ConfigService
  ) {
    this._currentCountry = '';
    this._httpClient = httpClient;
    this._baseUrl = environment.googleLocationUrl;
    this._apiKey = configurationService.get('googlemapKey');
    if (environment.production) {
      this._apiKey = configurationService.get('googlemapKey');
    } else {
      this._apiKey = environment.googleApiKey;
    }
  }

  loadPosition(): void {
    if ('geolocation' in navigator) {
      const self = this;
      navigator.geolocation.getCurrentPosition((position) => {
        self.onSuccessCallback.emit({ latitude: position.coords.latitude, logitude: position.coords.longitude });
      }, err => {
        self.onFailCallback.emit({ code: Number(err.code), message: err.message });
      });
    } else {
      this.onFailCallback.emit({ code: 0, message: 'geolocation not found' });
    }
  }

  async getCountry(latitude: number, longitude: number): Promise<string> {
    if (this._currentCountry) {
      return this._currentCountry;
    }
    const res = await this.getGeocodeInfo(latitude, longitude).toPromise();
    if (!res || (res && res.status === 'REQUEST_DENIED')) {
      return '';
    }
    const result = res.results.find(x => x.types[0] === 'country' && x.types[1] === 'political');
    const shortName = result.address_components[0].short_name;
    this._currentCountry = shortName;
    return shortName;
  }
  private getGeocodeInfo(latitude: number, longitude: number): Observable<any> {
    ConstantService.IsExternalRequest = true;
    return this._httpClient.get(`${this._baseUrl}?latlng=${latitude},${longitude}&key=${this._apiKey}&language=en`).pipe(map(res => {
      return res;
    }, err => {
      return null;
    }));
  }
}
