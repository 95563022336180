import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CompanyTypeInfoModel } from "src/applazy/shared/models/companytype.models";
import { CompanyTypeService } from "./companytype.service";

@Injectable()
export class CompanyTypeDataService {
    private _apiVersion = '1.0';
    private _companyTypes: Array<CompanyTypeInfoModel> = []
    constructor(private companyTypeService: CompanyTypeService) {

    }

    getAll(): Observable<Array<CompanyTypeInfoModel>> {
        return this.companyTypeService.getAll(this._apiVersion).pipe(map(companyTypes => {
            this._companyTypes = companyTypes || [];
            return this._companyTypes;
        }, err => {
            return [];
        }));
    }
}
