export enum TreeOpenState {
    None = 0,
    Pending = 1,
    Opened = 2
}

export enum GroupPosition {
    Insert = 0,
    Up = 1,
    Down = 2
}

export interface IDropTreeItemModel {
    id: string;
    parentId?: string;
    dropTreeName: string;
    dropTreePicture: string;
    hasChild: boolean;
    depth: number;
    isActive: boolean;
    canSelect: boolean;
    alreadyOpened: TreeOpenState;
    newChilds: Array<IDropTreeItemModel>;
    additionalInfo: any;
    showAdditionalInfo: boolean;
}

export class DropTreeItemModel implements IDropTreeItemModel {
    additionalInfo: string;
    alreadyOpened: TreeOpenState;
    dropTreeName: string;
    dropTreePicture: string;
    hasChild: boolean;
    depth: number;
    isActive: boolean;
    canSelect: boolean;
    id: any;
    newChilds: IDropTreeItemModel[];
    parentId: string;
    showAdditionalInfo: boolean;

    constructor() {
    this.additionalInfo = '';
    this.alreadyOpened = TreeOpenState.None;
    this.dropTreeName = '';
    this.dropTreePicture = '';
    this.hasChild = false;
    this.isActive = true;
    this.id = undefined;
    this.newChilds = [];
    this.parentId = undefined;
    this.showAdditionalInfo = false;
    }
}
