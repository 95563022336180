import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { IDropTreeItemModel, GroupPosition } from '../models/droptree.models';

@Injectable()
export class DropTreeSubscriptionsService {
    selectedQrId: string;
    hoveredQr: IDropTreeItemModel;
    moveByParent = false;
    positionType = GroupPosition.Insert;
    dragStarted = false;
    private dropTreeItemSelectClicked = new Subject<IDropTreeItemModel>();
    dropTreeItemSelectClicked$ = this.dropTreeItemSelectClicked.asObservable();
    private dropTreeItemAddClicked = new Subject<IDropTreeItemModel>();
    dropTreeItemAddClicked$ = this.dropTreeItemAddClicked.asObservable();
    private dropTreeItemEditClicked = new Subject<IDropTreeItemModel>();
    dropTreeItemEditClicked$ = this.dropTreeItemEditClicked.asObservable();
    private dropTreeItemDeleteClicked = new Subject<IDropTreeItemModel>();
    dropTreeItemDeleteClicked$ = this.dropTreeItemDeleteClicked.asObservable();
    private loadChildrensClicked = new Subject<IDropTreeItemModel>();
    loadChildrensClicked$ = this.loadChildrensClicked.asObservable();
    private dropTreeItemMoved = new Subject<{ source: IDropTreeItemModel, target: IDropTreeItemModel, positionType: GroupPosition }>();
    dropTreeItemMoved$ = this.dropTreeItemMoved.asObservable();
    private dropTreeItemFromParentDeleted = new Subject<IDropTreeItemModel>();
    dropTreeItemFromParentDeleted$ = this.dropTreeItemFromParentDeleted.asObservable();
    private dropTreeItemCloneToHovered = new Subject<IDropTreeItemModel>();
    dropTreeItemCloneToHovered$ = this.dropTreeItemCloneToHovered.asObservable();

    private openLoadedTrees = new Subject<boolean>();
    openLoadedTrees$ = this.openLoadedTrees.asObservable();
    constructor() {

    }

    selectDropTreeItem(dropTreeItem: IDropTreeItemModel) {
        this.hoveredQr = undefined;
        this.selectedQrId = dropTreeItem.id;
        this.dropTreeItemSelectClicked.next(dropTreeItem);
    }

    moveDropTreeItem(sourceDropTreeItem: IDropTreeItemModel) {
        this.dropTreeItemMoved.next({ source: sourceDropTreeItem, target: this.hoveredQr, positionType: this.positionType });
    }

    deleteParentOfDropTreeItem(dropTreeItem: IDropTreeItemModel) {
        this.dropTreeItemFromParentDeleted.next(dropTreeItem);
    }

    loadChildrens(dropTreeItem: IDropTreeItemModel) {
        this.loadChildrensClicked.next(dropTreeItem);
    }

    addDropTreeItem(dropTreeItem: IDropTreeItemModel) {
        this.dropTreeItemAddClicked.next(dropTreeItem);
    }

    editDropTreeItem(dropTreeItem: IDropTreeItemModel) {
        this.dropTreeItemEditClicked.next(dropTreeItem);
    }

    deleteDropTreeItem(dropTreeItem: IDropTreeItemModel) {
        this.dropTreeItemDeleteClicked.next(dropTreeItem);
    }

    openTrees(openState = true) {
        this.openLoadedTrees.next(openState);
    }

    copyInHoveredQr(dropTreeItem: IDropTreeItemModel) {
        this.dropTreeItemCloneToHovered.next(dropTreeItem);
    }
}
