import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardHome } from './guards/auth-guard-home.service';
import { AuthGuard } from './guards/auth-guard.service';
import { HomeComponent } from './main/home/home.component';
const routes: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home', component: HomeComponent, canActivate: [AuthGuardHome] },
    { path: 'profile', loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule) },
    { path: 'admin', loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule), canActivate: [AuthGuard] },
    { path: 'partner', loadChildren: () => import('./partner/partner.module').then((m) => m.PartnerModule), canActivate: [AuthGuard] },
    { path: 'saleportal', loadChildren: () => import('./saleportal/saleportal.module').then((m) => m.SalePortalModule), canActivate: [AuthGuard] },
    { path: 'employee', loadChildren: () => import('./employee-tracking/employeetracking.module').then((m) => m.EmployeeTrackingModule) },
    { path: 'subadmin', loadChildren: () => import('./subadmin/subadmin.module').then((m) => m.SubadminModule), canActivate: [AuthGuard] },
];
@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
