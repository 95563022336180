
import { Injectable, Optional } from '@angular/core';
import {
    HttpClient, HttpHeaders, HttpParams,
    HttpResponse, HttpEvent
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Configuration } from 'src/applazy/shared/models/configuration';
import { CustomHttpUrlEncodingCodec } from 'src/applazy/shared/models/encoder';
import { IFilterData } from '../shared/components/tables/tables.component';


@Injectable({
    providedIn: 'root'
})
export class MarketPlaceService {

    protected basePath = environment.marketplace;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || this.basePath;

        } else {
            this.configuration.basePath = this.basePath;
        }
    }

    public getAllDeliveryApplications(apiVersion: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ApplicationDeliveryReadModel>>;
    public getAllDeliveryApplications(apiVersion: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ApplicationDeliveryReadModel>>>;
    public getAllDeliveryApplications(apiVersion: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ApplicationDeliveryReadModel>>>;
    public getAllDeliveryApplications(apiVersion: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling getActiveDeliveryApplications.');
        }
        const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        let headers = this.defaultHeaders;
        // authentication (ImplicitOAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.configuration.basePath}/api/v${encodeURIComponent(String(apiVersion))}/ApplicationPortal/GetDeliveryApplications`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    public getActiveDeliveryApplicationsBySalePortal(apiVersion: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ApplicationDeliveryReadModel>>;
    public getActiveDeliveryApplicationsBySalePortal(apiVersion: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ApplicationDeliveryReadModel>>>;
    public getActiveDeliveryApplicationsBySalePortal(apiVersion: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ApplicationDeliveryReadModel>>>;
    public getActiveDeliveryApplicationsBySalePortal(apiVersion: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling getActiveDeliveryApplications.');
        }
        const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        let headers = this.defaultHeaders;
        // authentication (ImplicitOAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.configuration.basePath}/api/v${encodeURIComponent(String(apiVersion))}/ApplicationPortal/GetActiveDelivery`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    public getActiveDeliveryApplications(apiVersion: string, userId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ApplicationDeliveryReadModel>>;
    public getActiveDeliveryApplications(apiVersion: string, userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ApplicationDeliveryReadModel>>>;
    public getActiveDeliveryApplications(apiVersion: string, userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ApplicationDeliveryReadModel>>>;
    public getActiveDeliveryApplications(apiVersion: string, userId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling getActiveDeliveryApplications.');
        }
        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        let headers = this.defaultHeaders;
        // authentication (ImplicitOAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        if (userId) {
            queryParameters = queryParameters.set('userId', userId);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.configuration.basePath}/api/v${encodeURIComponent(String(apiVersion))}/ApplicationTeam/GetActiveDeliveryByTeam`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    public getActiveFeedbackByTeam(apiVersion: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ApplicationDeliveryReadModel>>;
    public getActiveFeedbackByTeam(apiVersion: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ApplicationDeliveryReadModel>>>;
    public getActiveFeedbackByTeam(apiVersion: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ApplicationDeliveryReadModel>>>;
    public getActiveFeedbackByTeam(apiVersion: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling getActiveFeedbackByTeam.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.configuration.basePath}/api/v${encodeURIComponent(String(apiVersion))}/ApplicationTeam/GetActiveFeedbackByTeam`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    public getActiveFeedbackBySalePortal(apiVersion: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ApplicationDeliveryReadModel>>;
    public getActiveFeedbackBySalePortal(apiVersion: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ApplicationDeliveryReadModel>>>;
    public getActiveFeedbackBySalePortal(apiVersion: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ApplicationDeliveryReadModel>>>;
    public getActiveFeedbackBySalePortal(apiVersion: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling getActiveFeedbackBySalePortal.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.configuration.basePath}/api/v${encodeURIComponent(String(apiVersion))}/applicationportal/GetActiveFeedback`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    public getDeliveryApplications(apiVersion: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ApplicationReadModel>>;
    public getDeliveryApplications(apiVersion: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ApplicationReadModel>>>;
    public getDeliveryApplications(apiVersion: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ApplicationReadModel>>>;
    public getDeliveryApplications(apiVersion: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling getActiveDeliveryApplications.');
        }
        const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        let headers = this.defaultHeaders;
        // authentication (ImplicitOAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ApplicationReadModel>>(`${this.configuration.basePath}/api/v${encodeURIComponent(String(apiVersion))}/app/delivery/applications`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
export interface ApplicationDeliveryReadModel {
    id: string;
    name: string;
    logo: string;
    state: string;
    teamState: string;
}
export class ApplicationReadModel implements IFilterData {
    applicationId: string;
    applicationName: string;
    applicationLogo: string;
    
    public get id() : string {
        return this.applicationId;
    }
    getDisplayName(language: string): string {
        return this.applicationName;
    }
}
