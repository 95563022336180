import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { CompanyTypeInfoModel } from "../shared/models/companytype.models";

@Injectable({
    providedIn: 'root'
})
export class CompanyTypeSubscriptionsService {
    companyTypeSelected: BehaviorSubject<string>;
    companyTypesChanged: BehaviorSubject<Array<CompanyTypeInfoModel>>;
    saveClicked: BehaviorSubject<boolean>;
    companyTypeId: string;
    itemType: string;
    oldItems: Array<string> = [];
    items: Array<string> = [];
    constructor() {
        this.companyTypeSelected = new BehaviorSubject<string>(null);
        this.companyTypesChanged = new BehaviorSubject<Array<CompanyTypeInfoModel>>([]);
        this.saveClicked = new BehaviorSubject<boolean>(false);
    }

    setItemTypes(itemType: string, items: Array<string>) {
        this.itemType = itemType;
        if (!items) {
            items = [];
        }
        this.items = [];
        this.oldItems = [];
        items.forEach(item => {
            this.items.push(item);
            this.oldItems.push(item);
        })
        this.items = items || [];
    }

    updateItemTypes() {
        this.setItemTypes(this.itemType, this.items);
    }
    resetItemTypes() {
        this.setItemTypes(this.itemType, this.oldItems);
    }

    clearItemTypes() {
        this.setItemTypes('', []);
    }

    selectCompanyType(id: string): void {
        this.companyTypeId = id;
        this.companyTypeSelected.next(id);
    }
    changeCompanyTypes(companyTypes: Array<CompanyTypeInfoModel>): void {
        this.companyTypesChanged.next(companyTypes);
    }
    saveChanges() {
        this.saveClicked.next(true);
    }
    existChanges(): boolean {
        if (this.items.length !== this.oldItems.length) {
            return true;
        }
        this.items.forEach(item => {
            if (this.oldItems.indexOf(item) === -1) {
                return true;
            }
        });
        return false;
    }
}
