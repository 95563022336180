import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { WordWriteModel } from 'src/applazy/generatedmodules/translation/api';
import { DropListModel } from '../models/droplist.models';

@Injectable()
export class DropListService {

    private saved = new Subject<{ model: DropListModel, success: boolean }>();
    saved$ = this.saved.asObservable();

    private deleted = new Subject<DropListModel>();
    deleted$ = this.deleted.asObservable();

    private dropListTranslatesChanged = new Subject<Array<{ id: string, translate: WordWriteModel }>>();
    dropListTranslatesChanged$ = this.dropListTranslatesChanged.asObservable();

    constructor() {

    }

    save(model: DropListModel, success: boolean) {
        this.saved.next({ model: model, success: success });
    }

    delete(model: DropListModel) {
        this.deleted.next(model);
    }

    setTranslates(translates: Array<{ id: string, translate: WordWriteModel }>) {
        this.dropListTranslatesChanged.next(translates);
    }
}
