import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonDirective } from './directives/button.directive';
import { GroupDirective } from './directives/group.directive';
import { TextEditorComponent } from './components/text-editor/text-editor.component';
import { SanitizerModule } from '../sanitizer/sanitizer.module';


@NgModule({
    imports: [
        CommonModule,
        SanitizerModule
    ],
    declarations: [
        TextEditorComponent,
        ButtonDirective,
        GroupDirective
    ],
    exports: [
        TextEditorComponent,
        ButtonDirective,
        GroupDirective
    ],
})
export class TextEditorModule { }
