import { Injectable, Optional } from '@angular/core';
import {
    HttpClient, HttpHeaders,
    HttpResponse, HttpEvent, HttpParams
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Configuration } from 'src/applazy/shared/models/configuration';
import { CompanyTypeInfoModel } from 'src/applazy/shared/models/companytype.models';

@Injectable({
    providedIn: 'root'
})
export class CompanyTypeService {

    protected basePath = environment.companyType;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || this.basePath;

        } else {
            this.configuration.basePath = this.basePath;
        }
    }

    public getAll(apiVersion: string, observe?: 'body', reportProgress?: boolean): Observable<Array<CompanyTypeInfoModel>>;
    public getAll(apiVersion: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CompanyTypeInfoModel>>>;
    public getAll(apiVersion: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CompanyTypeInfoModel>>>;
    public getAll(apiVersion: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling create.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.get<Array<CompanyTypeInfoModel>>(`${this.configuration.basePath}/api/v1/companytype/active/all`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
