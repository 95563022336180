
import { Injectable, Optional } from '@angular/core';
import {
    HttpClient, HttpHeaders, HttpParams,
    HttpResponse, HttpEvent
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { Configuration } from 'src/applazy/shared/models/configuration';
import { CustomHttpUrlEncodingCodec } from 'src/applazy/shared/models/encoder';
import { environment } from 'src/environments/environment';
import { IPartnersFilteredReadModel } from '../models/partner/partner.model';

@Injectable({
    providedIn: 'root'
})
export class SalePortalInfoService {

    protected basePath = environment.dataprocessing;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || this.basePath;

        } else {
            this.configuration.basePath = this.basePath;
        }
    }

    public partners(apiVersion: string, searchText: string, filters: { key: string, value: string }[], checkoutTypes: string, pageSize: number, pageIndex: number, nextPagesCount: number, startDate: string, endDate: string, observe?: 'body', reportProgress?: boolean): Observable<IPartnersFilteredReadModel>;
    public partners(apiVersion: string, searchText: string, filters: { key: string, value: string }[], checkoutTypes: string, pageSize: number, pageIndex: number, nextPagesCount: number, startDate: string, endDate: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IPartnersFilteredReadModel>>;
    public partners(apiVersion: string, searchText: string, filters: { key: string, value: string }[], checkoutTypes: string, pageSize: number, pageIndex: number, nextPagesCount: number, startDate: string, endDate: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IPartnersFilteredReadModel>>;
    public partners(apiVersion: string, searchText: string, filters: { key: string, value: string }[], checkoutTypes: string, pageSize: number, pageIndex: number, nextPagesCount: number, startDate: string, endDate: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        let headers = this.defaultHeaders;
        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (searchText) {
            queryParameters = queryParameters.set('searchText', searchText);
        }
        if (checkoutTypes) {
            queryParameters = queryParameters.set('checkoutTypes', checkoutTypes);
        }
        if (pageSize) {
            queryParameters = queryParameters.set('pageSize', <any>pageSize);
        }
        if (pageIndex !== null && pageIndex !== undefined) {
            queryParameters = queryParameters.set('pageIndex', <any>pageIndex);
        }
        if (nextPagesCount) {
            queryParameters = queryParameters.set('nextPagesCount', <any>nextPagesCount);
        }
        if (startDate) {
            queryParameters = queryParameters.set('startDate', <any>startDate);
        }
        if (endDate) {
            queryParameters = queryParameters.set('endDate', <any>endDate);
        }
        if (filters && filters.length > 0) {
            filters.forEach(f => {
                queryParameters = queryParameters.set(f.key, f.value);
            });
        }
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<IPartnersFilteredReadModel>(`${this.configuration.basePath}/api/v${apiVersion}/directsaleportalinfo/partners`,
            {
                withCredentials: this.configuration.withCredentials,
                params: queryParameters,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public partnersByKeys(apiVersion: string, teamKeys: string[], observe?: 'body', reportProgress?: boolean): Observable<IPartnersFilteredReadModel>;
    public partnersByKeys(apiVersion: string, teamKeys: string[], observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IPartnersFilteredReadModel>>;
    public partnersByKeys(apiVersion: string, teamKeys: string[], observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IPartnersFilteredReadModel>>;
    public partnersByKeys(apiVersion: string, teamKeys: string[], observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        let headers = this.defaultHeaders;
        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<IPartnersFilteredReadModel>(`${this.configuration.basePath}/api/v${apiVersion}/saleportalinfo/GetPartnersByKeys`,
            teamKeys,
            {
                withCredentials: this.configuration.withCredentials,
                params: queryParameters,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
}
