import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageService } from '../services/storage.service';
import { ConstantService, StorageKeys } from '../shared/services/constant.service';
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private storage: StorageService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (ConstantService.IsExternalRequest) {
      return next.handle(request);
    }
    const token = this.storage.retrieve('access_token');
    if (!token) {
      return next.handle(request);
    }
    const headers = {};

    headers['Authorization'] = `Bearer ${token}`;

    request = request.clone({
      setHeaders: headers
    });
    return next.handle(request);
  }
}
