import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/applazy/shared/models/User';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    private router: Router

  ) {

  }
  ngOnInit() {
    if (User.currentRole === 'admin') {
      this.router.navigate(['admin']);
      return;
    }
    if (User.currentRole === 'partner') {
      this.router.navigate([User.currentRole + '/' + 'profile/info']);
    }
    if (User.currentRole === 'saleportal') {
      this.router.navigate([User.currentRole + '/' + 'profile/saleprofile']);
    }
    if (User.currentRole === 'subadmin') {
      this.router.navigate([User.currentRole + '/' + 'profile/subadminprofile']);
    }
  }
}
