import { Subject } from 'rxjs';
import { OrderStatusModel } from 'src/applazy/shared/models/orderTypeSetting';
import { TimePickerItemModel, TimePickerModel } from '../../components/times/models/TimesModels';
import { UTCDatePipe } from '../../pipes/utcdate.pipe';
import { RelevantLocation } from '../../models/partnermodels/relevantLocation';

export class OrderStatusInfoService {
  private messageSubject = new Subject<InfoState>();
  public dialogConfirmation: () => void;
  public dialogRejection: () => void;
  public model: ChangeStatusWriteModel;
  public currentStatus: OrderStatusModel;
  public nextStatuses: OrderStatusModel[];

  infoState = this.messageSubject.asObservable();

  public showconfirm(
    currentStatus: OrderStatusModel, 
    nextStatuses: OrderStatusModel[], 
    applications: Array<any>,
    locations: Array<RelevantLocation>,
    showEstimationTime = true, 
    showUserTime = true): Promise<{ confirmed: boolean, model: ChangeStatusWriteModel }> {
    if (!this.model) {
      this.model = new ChangeStatusWriteModel(nextStatuses[0].statusId);
    }
    this.model.applications = applications;
    this.model.locations = locations;
    if (this.model.locationId) {
      this.model.selectedLocation = this.model.locations?.find(x=>x.id === this.model.locationId);
    }
    if (!this.model.selectedLocation && this.model.locations && this.model.locations.length > 0) {
      this.model.selectedLocation = this.model.locations[0];
      this.model.locationId = this.model.locations[0].id;
    }
    if (this.model.applicationId) {
      this.model.selectedApplication = this.model.applications?.find(x=>x.id === this.model.applicationId);
    }
    this.model.statusId = nextStatuses[0].statusId;
    this.model.showEstimationTime = showEstimationTime;
    this.model.showUserTime = showUserTime;
    this.currentStatus = currentStatus;
    this.nextStatuses = nextStatuses;
    return new Promise<{ confirmed: boolean, model: ChangeStatusWriteModel }>((resolve, reject) => {
      this.dialogConfirmation = () => {
        const date = this.model.estimationDateTime;
        const day = date.getDate() < 10 ? '0' + date.getDate().toString() : date.getDate().toString();
        const month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1).toString() : (date.getMonth() + 1).toString();
        const year = date.getFullYear().toString();
        const hour = date.getHours() < 10 ? '0' + date.getHours().toString() : date.getHours().toString();
        const minute = date.getMinutes() < 10 ? '0' + date.getMinutes().toString() : date.getMinutes().toString();
        const second = date.getSeconds() < 10 ? '0' + date.getSeconds().toString() : date.getSeconds().toString();
        // 2021-01-27T22:35:59.059Z
        this.model.estimationTime = (year + '-' + month + '-' + day + 'T' + hour + ':' + minute + ':' + second);

        const udate = this.model.userDateTime;
        const uday = udate.getDate() < 10 ? '0' + udate.getDate().toString() : udate.getDate().toString();
        const umonth = (udate.getMonth() + 1) < 10 ? '0' + (udate.getMonth() + 1).toString() : (udate.getMonth() + 1).toString();
        const uyear = udate.getFullYear().toString();
        const uhour = udate.getHours() < 10 ? '0' + udate.getHours().toString() : udate.getHours().toString();
        const uminute = udate.getMinutes() < 10 ? '0' + udate.getMinutes().toString() : udate.getMinutes().toString();
        const usecond = udate.getSeconds() < 10 ? '0' + udate.getSeconds().toString() : udate.getSeconds().toString();
        // 2021-01-27T22:35:59.059Z
        this.model.userTime = (uyear + '-' + umonth + '-' + uday + 'T' + uhour + ':' + uminute + ':' + usecond);

        resolve({ confirmed: true, model: this.model });
      };
      this.dialogRejection = () => resolve({ confirmed: false, model: this.model });
      this.show();
    }).catch(err => new Promise<{ confirmed: boolean, model: ChangeStatusWriteModel }>((resolve, reject) => { }));
  }

  private show() {
    this.messageSubject.next(<InfoState>{ show: true });
  }

  hide() {
    this.messageSubject.next(<InfoState>{ show: false });
  }
}

export interface InfoState {
  show: boolean;
}

export class ChangeStatusWriteModel {
  orderId: number;
  statusId: number;
  description: string;
  estimationTime: string;
  estimationDateTime: Date;
  estimationTimeHM: string;
  showEstimationTime = true;
  userTime: string;
  userDateTime: Date;
  userTimeHM: string;
  showUserTime = true;
  editable: boolean;
  estimationTimePickerModel: TimePickerModel;
  userTimePickerModel: TimePickerModel;
  applicationId: string;
  locationId: string;
  selectedApplication: any;
  selectedLocation: RelevantLocation;
  applications: Array<any>;
  locations: Array<RelevantLocation>;
  isResend: boolean;

  constructor(statusId: number, orderId = 0, editable = true) {
    this.orderId = 0;
    if (orderId) {
      this.orderId = orderId;
    }
    this.statusId = statusId;
    this.editable = editable;
    this.description = '';
  }

  setEstimationTime(estimationTime: Date | string): void {
    const date = new Date(estimationTime);
    this.estimationDateTime = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    // this.estimationDateTime = new Date(estimationTime);
    const hours = this.estimationDateTime.getHours();
    const minutes = this.estimationDateTime.getMinutes();
    this.estimationDateTime.setHours(hours, minutes, 59, 59);
    this.estimationTimeHM = (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes.toString() : minutes);
    this.estimationTimePickerModel = new TimePickerModel();
    this.estimationTimePickerModel.hourModel = new TimePickerItemModel(hours, 24);
    this.estimationTimePickerModel.minuteModel = new TimePickerItemModel(minutes, 60);
  }

  setUserTime(userTime: Date): void {
    const date = new Date(userTime);
    this.userDateTime = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    // this.userDateTime = new Date(userDateTime);
    const hours = this.userDateTime.getHours();
    const minutes = this.userDateTime.getMinutes();
    this.userDateTime.setHours(hours, minutes, 59, 59);
    this.userTimeHM = (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes.toString() : minutes);
    this.userTimePickerModel = new TimePickerModel();
    this.userTimePickerModel.hourModel = new TimePickerItemModel(hours, 24);
    this.userTimePickerModel.minuteModel = new TimePickerItemModel(minutes, 60);
  }

  getEstimationString(date: Date, setZ = true): string {
    const day = date.getDate() < 10 ? '0' + date.getDate().toString() : date.getDate().toString();
    const month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1).toString() : (date.getMonth() + 1).toString();
    const year = date.getFullYear().toString();
    const hour = date.getHours() < 10 ? '0' + date.getHours().toString() : date.getHours().toString();
    const minute = date.getMinutes() < 10 ? '0' + date.getMinutes().toString() : date.getMinutes().toString();
    const second = date.getSeconds() < 10 ? '0' + date.getSeconds().toString() : date.getSeconds().toString();
    // 2021-01-27T22:35:59.059Z
    return (year + '-' + month + '-' + day + 'T' + hour + ':' + minute + ':' + second) + (setZ ? 'Z' : '');
  }
}
