import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UUID } from 'angular2-uuid';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { DirectTranslationService, LanguageReadModel, TranslationService, WordLengWriteModel, WordWriteModel } from 'src/applazy/generatedmodules/translation/api';
import { WordTranslate } from 'src/applazy/generatedmodules/translation/api';
import { PartnerTranslationDataService } from '../partner/services/partnertranslationdata.service';
import { DirectTranslationDataService } from '../saleportal/services/directtranslationdata.service';
import { NOT_EXIST_TRANSLATION } from '../shared/handlers/lazyFakeMissingTranslationHandler';
import { User } from '../shared/models/User';
import { StorageKeys } from '../shared/services/constant.service';
import { AuthService } from './auth.service';
import { BaseTranslationDataService } from './basetranslationdata.service';
import { StorageService } from './storage.service';
import { SubadminTranslationDataService } from '../subadmin/services/subadminTranslationData.service';

@Injectable({
    providedIn: 'root'
})
export class TranslationLanguageService {
    private _wordWriteModels: WordWriteModel[] = [];
    public mainLanguageManualChanged: boolean;
    languagesArr: Array<LanguageReadModel> = [];
    authLanguagesArr: Array<LanguageReadModel>;
    languages$ = new Subject<Array<LanguageReadModel>>();
    authLanguages$ = new Subject<Array<LanguageReadModel>>();
    languages = this.languages$.asObservable();
    authLanguages = this.authLanguages$.asObservable();
    private mainPanelLanguageChanged$ = new Subject<LanguageReadModel>();
    mainPanelLanguageChanged = this.mainPanelLanguageChanged$.asObservable();
    private subLanguageChanged$ = new Subject<LanguageReadModel>();
    subLanguageChanged = this.subLanguageChanged$.asObservable();
    private _baseTranslationDataService: BaseTranslationDataService;
    private readonly _loadUrl = true;
    constructor(
        private authService: AuthService,
        private translateService: TranslateService,
        private storageService: StorageService,
        private translationService: TranslationService,
        private directtranslationService: DirectTranslationService
    ) {
        this.authService.authorized.subscribe(val => {
            if (!val) {
                this.mainLanguageManualChanged = false;
            }
            if (!val && this.authLanguagesArr && this.authLanguagesArr.length > 0) {
                this.authLanguagesArr = [];
                this._wordWriteModels = [];
            }
        });
    }
    loadLanguages(reqLang?: string) {
        if (this.authService.isAuthorized) {
            return;
        }
        if (!this.languagesArr || this.languagesArr.length === 0) {
            this.translationService.apiV1TranslationLangsDefallGet('1.0', this._loadUrl).subscribe(res => {
                if (res && res.length > 0) {
                    let language = this.storageService.retrieve(StorageKeys.TranslationLanguageKey);
                    if (reqLang && res.findIndex(x => x.name === reqLang) > -1) {
                        language = reqLang;
                    }
                    if (!language || !language.trim()) {
                        language = res[0].name;
                    }
                    this.storageService.store(StorageKeys.TranslationLanguageKey, language);
                    this.translateService.setDefaultLang(language);
                    this.translateService.use(language).subscribe(t => {
                        this.languagesArr = res;
                        this.languages$.next(res);
                        const lang = res.find(x => x.name === language);
                        this.mainPanelLangChanged(lang, true);
                    });
                }
            }, err => { });
        } else {
            this.languages$.next(this.languagesArr);
        }
    }

    loadAuthLanguages() {
        if (User.currentRole) {
            if (User.currentRole === 'saleportal') {
                this._baseTranslationDataService = new DirectTranslationDataService(this.directtranslationService);
            } else {
                if (User.currentRole === 'subadmin') {
                    this._baseTranslationDataService = new SubadminTranslationDataService(this.translationService);
                } else {
                    this._baseTranslationDataService = new PartnerTranslationDataService(this.translationService);
                }
            }
        }
        if (!this.authLanguagesArr || this.authLanguagesArr.length === 0) {
            this._baseTranslationDataService.getAuthLanguages(this._loadUrl).subscribe(res => {
                if (res && res.length > 0) {
                    let language = this.storageService.retrieve(StorageKeys.TranslationLanguageKey);
                    if (!language || !language.trim() || res.filter(x => x.selected).findIndex(x => x.name === language) === -1) {
                        const selectedLangIndex = res.findIndex(x => x.selected);
                        if (selectedLangIndex === -1) {
                            res[0].selected = true;
                            language = res[0].name;
                        } else {
                            language = res[selectedLangIndex].name;
                        }
                        this.storageService.store(StorageKeys.TranslationLanguageKey, language);
                    }
                    this.translateService.setDefaultLang(language);
                    this.translateService.use(language).subscribe(t => {
                        this.authLanguagesArr = res;
                        this.authLanguages$.next(res);
                        const lang = res.find(x => x.name === language);
                        this.mainPanelLangChanged(lang, true);
                    });
                }
            }, err => { });
        } else {
            this.authLanguages$.next(this.authLanguagesArr);
        }
    }

    saveLanguages(langs: Array<LanguageReadModel>, mainLanguageKey: string) {
        const selectedLangs = langs.filter(x => x.selected).map(y => y.name);
        const index = selectedLangs.findIndex(x => x === mainLanguageKey);
        selectedLangs.splice(index, 1);
        selectedLangs.splice(0, 0, mainLanguageKey);
        this.authLanguagesArr = langs;
        const authIndex = this.authLanguagesArr.findIndex(x => x.name === mainLanguageKey);
        const item = Object.assign({}, this.authLanguagesArr[authIndex]);
        this.authLanguagesArr.splice(authIndex, 1);
        this.authLanguagesArr.splice(0, 0, item);
        let language = this.storageService.retrieve(StorageKeys.TranslationLanguageKey);
        if (!language || !language.trim() || selectedLangs.findIndex(x => x === language) === -1) {
            language = selectedLangs[0];
            this.storageService.store(StorageKeys.TranslationLanguageKey, language);
        }
        this.translateService.setDefaultLang(language);
        this.translateService.use(language).subscribe(set => {
            this.authLanguages$.next(this.authLanguagesArr);
            this._baseTranslationDataService.saveAuthLanguages(selectedLangs).subscribe(res => {
            }, err => { });
        });
    }

    changeSubLang(data: LanguageReadModel, items: Array<WordWriteModel>): Observable<boolean> {
        return this._baseTranslationDataService.getWordByKey(items.filter(i => i && i.key).map(x => x.key), data.name).pipe(map((t: WordTranslate[]) => {
            items.forEach(item => {
                if (item && item.key) {
                    let value = '';
                    const keyIndex = t.findIndex((x) => x.key === item.key);
                    if (keyIndex !== -1) {
                        if (!t[keyIndex].value || t[keyIndex].value === item.key || t[keyIndex].value.trim() === '') {
                            t[keyIndex].value = '';
                        }
                        value = t[keyIndex].value;
                    }
                    let index = item.translateParams.findIndex((param) => param.language === data.name);
                    if (index === -1) {
                        const param = <WordLengWriteModel>{
                            language: data.name,
                            value: ' '
                        };
                        item.translateParams.push(param);
                        index = item.translateParams.length - 1;
                    }
                    item.translateParams[index].value = value;
                }
            });
            this.subLanguageChanged$.next(data);
            return true;
        }, err => { }));
    }

    changeSubLangFromExist(items: Array<WordWriteModel>): Observable<boolean> {
        return this.translateService.get(items.filter(i => i && i.key).map(x => x.key)).pipe(map((t) => {
            items.forEach(item => {
                if (item && item.key) {
                    let index = item.translateParams.findIndex((param) => param.language === this.translateService.currentLang);
                    if (index === -1) {
                        const param = <WordLengWriteModel>{
                            language: this.translateService.currentLang,
                            value: ' '
                        };
                        item.translateParams.push(param);
                        index = item.translateParams.length - 1;
                    }
                    let text;
                    if (!t[item.key] || t[item.key] === item.key || t[item.key].trim() === '' || t[item.key] === NOT_EXIST_TRANSLATION) {
                        text = '';
                    } else {
                        text = t[item.key];
                    }
                    item.translateParams[index].value = text;
                }
            });
            this.subLanguageChanged$.next(this.authLanguagesArr.find(x => x.name === this.translateService.currentLang));
            return true;
        }, err => { }));
    }

    setInExist(items: Array<WordWriteModel>, mustMatchGuid = false, checkWords = true) {
        items.forEach(item => {
            if (!item.key || (mustMatchGuid && !this.matchToGuid(item.key))) {
                item.key = UUID.UUID();
            }
            let existDefault = item.translateParams.findIndex(tp => tp.language === this.translateService.currentLang) > -1;
            item.translateParams.forEach(translateParam => {
                const translateObj = {};
                translateObj[item.key] = translateParam.value;
                if (!translateParam.language && !existDefault) {
                    existDefault = true;
                    translateParam.language = this.translateService.currentLang;
                }
                if (translateParam.language) {
                    this.translateService.setTranslation(translateParam.language, translateObj, true);
                }
            });
            if (checkWords) {
                const index = this._wordWriteModels.findIndex(x => x.key === item.key);
                if (index > -1) {
                    this._wordWriteModels.splice(index, 1);
                }
                this._wordWriteModels.push(item);
            }
        });
    }

    private matchToGuid(guid: string): boolean {
        if (!guid || !guid.match('[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}')) {
            return false;
        }
        return true;
    }

    mainPanelLangChanged(lang: LanguageReadModel, autoChange = false) {
        if (!this._wordWriteModels) {
            this._wordWriteModels = [];
        }
        if (!autoChange) {
            this.mainLanguageManualChanged = true;
        }
        this.setInExist(this._wordWriteModels, true, false);
        this.mainPanelLanguageChanged$.next(lang);
    }
}
