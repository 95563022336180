import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageService } from '../services/storage.service';
import { ConstantService, StorageKeys } from '../shared/services/constant.service';
import { UUID } from 'angular2-uuid';
import { ClientTokens, User } from '../shared/models/User';
@Injectable()
export class CredentialsInterceptor implements HttpInterceptor {
  constructor(
    private storage: StorageService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (ConstantService.IsExternalRequest) {
      ConstantService.IsExternalRequest = false;
      return next.handle(request);
    }
    const headers = {};
    const salePortalKey = this.storage.retrieve('SALE_PORTAL_KEY');
    const originSalePortalKey = User.OriginSalePortalKey;
    const teamReferer = this.storage.retrieve(StorageKeys.SALE_PORTAL_SELECTED_PARTNER);
    const adminReferer = this.storage.retrieve(StorageKeys.SALE_PORTAL_COMPANY_SELECTED_PARTNER);
    let companyTypeId = this.storage.retrieve(StorageKeys.SALE_PORTAL_COMPANY_TYPE_KEY);
    if (!companyTypeId) {
      companyTypeId = ConstantService.EmptyGuid;
    }
    if (salePortalKey) {
      headers['saleportalkey'] = salePortalKey;
    }
    if (originSalePortalKey) {
      headers['saleportalkey'] = originSalePortalKey;
    }
    if (salePortalKey && teamReferer) {
      headers['teamreferer'] = `https://${teamReferer}.bonee.net`;
      headers['autoDecode'] = 'false';
    }
    if (salePortalKey && adminReferer && (!teamReferer || teamReferer === adminReferer) && companyTypeId) {
      headers['teamreferer'] = `https://${adminReferer}.bonee.net`;
      headers['CompanyTypeKey'] = companyTypeId;
    }
    if (!headers['x-requestId']) {
      headers['x-requestId'] = UUID.UUID();
    }
    let clientToken = ClientTokens.SalePortal;
    if (User.ClientToken) {
      clientToken = User.ClientToken;
    }
    headers['clienttoken'] = clientToken;
    request = request.clone({
      setHeaders: headers
    });
    return next.handle(request);
  }
}
