import { ActivatedRoute } from "@angular/router";

export class User {
  public static sub: string;
  public static team: string;
  public static ClientToken: string;
  public static userName: string;
  public static roles: string[];
  public static currentRole: string;
  public static Role: string;
  public static Currencies: string[];
  public static CountryCode: string;
  public static ReferenceKey: string;
  public static OriginSalePortalKey: string;
  public static NotificationEmail: string;
  public static AdminHostName: string;

  public static create(decodedToken: any) {
    User.sub = decodedToken.sub;
    User.team = decodedToken.team;
    User.userName = decodedToken.email;
    User.roles = [];
    // 'Admin'
    // 'Partner'
    // 'SalePortal'
    if (Array.isArray(decodedToken.role)) {
      User.roles = decodedToken.role as string[];
    } else {
      User.roles = [(decodedToken.role as string)];
    }
    if (User.roles.includes('SalePortal')) {
      User.Role = 'SalePortal';
      User.ClientToken = ClientTokens.SalePortalAdmin;
    } else {
      if (User.roles.includes('Partner')) {
        User.Role = 'Partner';
        User.ClientToken = ClientTokens.Partner;
      } else {
        if (User.roles.includes('Admin')) {
          User.Role = 'Admin';
          User.ClientToken = ClientTokens.Admin;
        } else {
          if (User.roles.includes('Subadmin')) {
            User.Role = 'Subadmin';
            User.ClientToken = ClientTokens.Subadmin;
          }
        }
      }
    }
    if (!User.ClientToken) {
      User.ClientToken = ClientTokens.SalePortal;
    }
    User.currentRole = User.Role.toLowerCase();
  }

  public static setCurrencies(currencies: string[]) {
    User.Currencies = currencies;
  }
  public static setCountryCode(countryCode: string) {
    User.CountryCode = countryCode;
  }

  public static setReferenceKey(referenceKey: string) {
    User.ReferenceKey = referenceKey;
  }
  public static setOriginSalePortalKey(salePortalKey: string) {
    User.OriginSalePortalKey = salePortalKey;
  }
  public static setNotificationEmail(notificationEmail: string) {
    User.NotificationEmail = notificationEmail;
  }

  public static setAdminHostName(hostName: string) {
    User.AdminHostName = hostName;
  }

  public static clear() {
    User.sub = '';
    User.team = '';
    User.ClientToken = '';
    User.userName = '';
    User.roles = [];
    User.currentRole = '';
    User.Role = '';
    User.Currencies = [];
    User.ReferenceKey = '';
    User.OriginSalePortalKey = '';
    User.NotificationEmail = '';
    User.AdminHostName = '';
  }

  public static routerUrl(activatedRoute: ActivatedRoute): string {
    const data = activatedRoute.snapshot.data;
    const params = activatedRoute.snapshot.params;
    return this._routerUrl(data, params);
  }

  private static _routerUrl(data: any, routeParams: any): string {
    let routerUrl = '';
    if (data) {
      let name = data.name as string;
      const params = data.params as string[];
      if (name) {
        routerUrl = '/' + name;
      }
      if (params && params.length > 0) {
        params.forEach(param => {
          const paramValue = routeParams[param];
          if (paramValue) {
            const index = routerUrl.indexOf('#' + param);
            if (index === -1) {
              routerUrl += '/' + paramValue;
            }
          }
          while (routerUrl.indexOf('#' + param) !== -1) {
            routerUrl = routerUrl.replace('#' + param, paramValue);
          }
        });
      }
    }
    return routerUrl;
  }
}

export const ClientTokens = {
  Admin: 'ut0Jg5XF2UTJ/d6E8IZ5U8VUHCTNzrjQKNyjEmtwtk4udqqTAEepxhf27ggNk3jwYjIzYjIwYTJjODMzNDA4NQ==',
  Partner: '0vsBCKW8wdw1bzBG2hbs0zjzXOVwYV9xwSpuW9lN0tQUXXgERBFouc8Rgt/glGEdYjIzYjIwYTJjODMzNDA4NQ==',
  SalePortalAdmin: '+DCkBa3bMz7uvcZfGmnHHXmpGfQpn0r0romgSsZD/4TjmSshqjnUW0A5iSvH342aYjIzYjIwYTJjODMzNDA4NQ==',
  SalePortal: 'Zu8QaW8fLRoDLYJc5wVq/Tmc5u3BdnfR/sQ7LSgG7HTI48zQgmpHYkUEjr3/bPg6YjIzYjIwYTJjODMzNDA4NQ==',
  Subadmin: 'fs46WpWvphSV76uYbnDjm6DScpheu2QOfEUcDEX7UqwUn544HMs9p8ipoVygeYURYjIzYjIwYTJjODMzNDA4NQ=='
};
