import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IChangePasswordModel } from '../../partner/models/security.models';

@Injectable()
export class SecurityService {

    constructor(
        private httpClient: HttpClient
    ) {
    }

    changeTeamPassword(model: IChangePasswordModel) {
        let headers = new HttpHeaders();
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'text/json'
        ];
        headers = headers.set('Accept', 'application/json');

        headers = headers.set('Content-Type', 'application/json');

        return this.httpClient.post<boolean>(`${environment.identityUrl}/IdentityServer/ChangeTeamPassword`,
            model,
            {
                withCredentials: false,
                headers: headers,
                observe: 'body',
                reportProgress: false
            }
        );
    }
}
