import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HelperService } from 'src/applazy/shared/services/helper.service';
import { ImageStoreService } from './imagestore/imageStore.service';
export enum UrlType {
    DownloadUrl = 'DownloadUrl',
    BlobUrl = 'BlobUrl'
}
export enum ImageType {
    Logo = 'Logo',
    Background = 'Background'
}

@Injectable()
export class ImageStoreDataService {
    private _apiVersion = '1.0';
    constructor(
        private imageStoreService: ImageStoreService,
        private helperService: HelperService
    ) {
    }

    upload(
        imageSrc: string,
        pictureId: string,
        urlType: any,
        uploadOrigin = false
    ): Observable<string> {
        const block = imageSrc.split(';');
        const contentType = block[0].split(':')[1];
        const realData = block[1].split(',')[1];
        const blob = this.helperService.b64toBlob(realData, contentType, 100);
        return this.imageStoreService.uploadImage(this._apiVersion, pictureId, urlType, blob, uploadOrigin).pipe(map(res => {
            return res;
        }, err => {
            return undefined;
        }));
    }
}
