export class TimePickerModel {
  hourModel: TimePickerItemModel;
  minuteModel: TimePickerItemModel;

  constructor() {
    this.hourModel = new TimePickerItemModel();
    this.minuteModel = new TimePickerItemModel();
  }

  getCompareValue(): number {
    return Number(this.hourModel.valueString + this.minuteModel.valueString);
  }

  getValueString() {
    return this.hourModel.valueString + ':' + this.minuteModel.valueString;
  }
}

export class TimePickerItemModel {
  value: number;
  maxValue: number;
  firstClicked: boolean;
  selected: boolean;
  public get valueString(): string {
    return this.value < 10 ? '0' + this.value.toString() : this.value.toString();
  }

  constructor(value: number = 0, maxValue = 0) {
    this.value = value;
    this.maxValue = maxValue;
    this.firstClicked = false;
    this.selected = false;
    if (value >= maxValue) {
      this.value = 0;
    }
  }
}
