
import { Inject, Injectable, Optional } from '@angular/core';
import {
    HttpClient, HttpHeaders, HttpParams,
    HttpResponse, HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { LanguageReadModel } from '../model/languageReadModel';
import { WordTranslate } from '../model/wordTranslate';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { WordViewModel } from '../model/wordViewModel';
import { SaveHostLanguagesCommand } from 'src/applazy/saleportal/models/translation/saveHostLanguages.model';


@Injectable({
    providedIn: 'root'
})
export class DirectTranslationService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    public apiV1DirectTranslationHostLanguageGet(language: string, hostName?: string, apiVersion?: string, observe?: 'body', reportProgress?: boolean): Observable<{ [key: string]: string; }>;
    public apiV1DirectTranslationHostLanguageGet(language: string, hostName?: string, apiVersion?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<{ [key: string]: string; }>>;
    public apiV1DirectTranslationHostLanguageGet(language: string, hostName?: string, apiVersion?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<{ [key: string]: string; }>>;
    public apiV1DirectTranslationHostLanguageGet(language: string, hostName?: string, apiVersion?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (language === null || language === undefined) {
            throw new Error('Required parameter language was null or undefined when calling apiV1DirectTranslationHostLanguageGet.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (apiVersion !== undefined && apiVersion !== null) {
            queryParameters = queryParameters.set('api-version', <any>apiVersion);
        }

        let headers = this.defaultHeaders;

        // authentication (ImplicitOAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<{ [key: string]: string; }>(`${this.configuration.basePath}/api/v1/DirectTranslation/host/${hostName}/${encodeURIComponent(String(language))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    public apiV1DirectTranslationPortalLanguageGet(language: string, apiVersion?: string, observe?: 'body', reportProgress?: boolean): Observable<{ [key: string]: string; }>;
    public apiV1DirectTranslationPortalLanguageGet(language: string, apiVersion?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<{ [key: string]: string; }>>;
    public apiV1DirectTranslationPortalLanguageGet(language: string, apiVersion?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<{ [key: string]: string; }>>;
    public apiV1DirectTranslationPortalLanguageGet(language: string, apiVersion?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (language === null || language === undefined) {
            throw new Error('Required parameter language was null or undefined when calling apiV1DirectTranslationPortalLanguageGet.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (apiVersion !== undefined && apiVersion !== null) {
            queryParameters = queryParameters.set('api-version', <any>apiVersion);
        }

        let headers = this.defaultHeaders;

        // authentication (ImplicitOAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<{ [key: string]: string; }>(`${this.configuration.basePath}/api/v1/DirectTranslation/portal/${encodeURIComponent(String(language))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    public apiV1DirectTranslationLangsAllGet(apiVersion?: string, loadUrl?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<LanguageReadModel>>;
    public apiV1DirectTranslationLangsAllGet(apiVersion?: string, loadUrl?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LanguageReadModel>>>;
    public apiV1DirectTranslationLangsAllGet(apiVersion?: string, loadUrl?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LanguageReadModel>>>;
    public apiV1DirectTranslationLangsAllGet(apiVersion?: string, loadUrl?: boolean, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (apiVersion !== undefined && apiVersion !== null) {
            queryParameters = queryParameters.set('api-version', <any>apiVersion);
        }
        if (loadUrl !== undefined && loadUrl !== null) {
            queryParameters = queryParameters.set('loadUrl', <any>loadUrl);
        }

        let headers = this.defaultHeaders;

        // authentication (ImplicitOAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<LanguageReadModel>>(`${this.configuration.basePath}/api/v1/DirectTranslation/langs/portalall`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    public apiV1TranslationLanguageLanguagePost(language: string, apiVersion: string, requestBody?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<WordTranslate>>;
    public apiV1TranslationLanguageLanguagePost(language: string, apiVersion: string, requestBody?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WordTranslate>>>;
    public apiV1TranslationLanguageLanguagePost(language: string, apiVersion: string, requestBody?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WordTranslate>>>;
    public apiV1TranslationLanguageLanguagePost(language: string, apiVersion: string, requestBody?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (language === null || language === undefined) {
            throw new Error('Required parameter language was null or undefined when calling apiV1TranslationLanguageLanguagePost.');
        }
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling apiV1TranslationLanguageLanguagePost.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (apiVersion !== undefined && apiVersion !== null) {
            queryParameters = queryParameters.set('api-version', <any>apiVersion);
        }

        let headers = this.defaultHeaders;

        // authentication (ImplicitOAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<WordTranslate>>(`${this.configuration.basePath}/api/v1/DirectTranslation/language/${encodeURIComponent(String(language))}`,
            requestBody,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    public apiV1TranslationLangsSavePortalPost(apiVersion: string, requestBody?: SaveHostLanguagesCommand, observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public apiV1TranslationLangsSavePortalPost(apiVersion: string, requestBody?: SaveHostLanguagesCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public apiV1TranslationLangsSavePortalPost(apiVersion: string, requestBody?: SaveHostLanguagesCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public apiV1TranslationLangsSavePortalPost(apiVersion: string, requestBody?: SaveHostLanguagesCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling apiV1TranslationLangsSavePortalPost.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (apiVersion !== undefined && apiVersion !== null) {
            queryParameters = queryParameters.set('api-version', <any>apiVersion);
        }

        let headers = this.defaultHeaders;

        // authentication (ImplicitOAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<string>>(`${this.configuration.basePath}/api/v1/DirectTranslation/langs/saveportal`,
            requestBody,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    public apiV1TranslationLangsDefallGet(apiVersion: string, loadUrl: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<LanguageReadModel>>;
    public apiV1TranslationLangsDefallGet(apiVersion: string, loadUrl: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LanguageReadModel>>>;
    public apiV1TranslationLangsDefallGet(apiVersion: string, loadUrl: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LanguageReadModel>>>;
    public apiV1TranslationLangsDefallGet(apiVersion: string, loadUrl: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling apiV1TranslationLangsDefallGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (apiVersion !== undefined && apiVersion !== null) {
            queryParameters = queryParameters.set('api-version', <any>apiVersion);
        }
        if (loadUrl !== undefined && loadUrl !== null) {
            queryParameters = queryParameters.set('loadUrl', <any>loadUrl);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<LanguageReadModel>>(`${this.configuration.basePath}/api/v1/DirectTranslation/langs/defall`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    public getStatuses(apiVersion: string, language: string, requestBody?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<WordViewModel>>;
    public getStatuses(apiVersion: string, language: string, requestBody?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WordViewModel>>>;
    public getStatuses(apiVersion: string, language: string, requestBody?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WordViewModel>>>;
    public getStatuses(apiVersion: string, language: string, requestBody?: Array<string>, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling getStatuses.');
        }

        if (language === null || language === undefined) {
            throw new Error('Required parameter language was null or undefined when calling getStatuses.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (apiVersion !== undefined && apiVersion !== null) {
            queryParameters = queryParameters.set('api-version', <any>apiVersion);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<WordViewModel>>(`${this.configuration.basePath}/api/v1/DirectTranslation/statuses/${language}`,
            requestBody,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    public apiV1TranslationLangsRefreshPost(apiVersion: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiV1TranslationLangsRefreshPost(apiVersion: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiV1TranslationLangsRefreshPost(apiVersion: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiV1TranslationLangsRefreshPost(apiVersion: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling apiV1TranslationLangsRefreshPost.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (apiVersion !== undefined && apiVersion !== null) {
            queryParameters = queryParameters.set('api-version', <any>apiVersion);
        }

        let headers = this.defaultHeaders;

        // authentication (ImplicitOAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<boolean>(`${this.configuration.basePath}/api/v1/DirectTranslation/langs/refresh`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
}
