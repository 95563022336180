
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DatepickerComponent } from './datepicker.component';
import { SlimScrollDirective } from './slimscroll.directive';

@NgModule({
    declarations: [DatepickerComponent, SlimScrollDirective],
    imports: [CommonModule, FormsModule],
    exports: [DatepickerComponent, CommonModule, FormsModule]
})
export class DatepickerModule { }
