
import { Injectable, Optional } from '@angular/core';
import {
    HttpClient, HttpHeaders, HttpParams,
    HttpResponse, HttpEvent
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Configuration } from '../../models/configuration';
import { CustomHttpUrlEncodingCodec } from '../../models/encoder';
import { TemporaryFileReadModel } from '../../models/imagestore/temporaryFileReadModel';


@Injectable({
    providedIn: 'root'
})
export class TemporaryImagesService {

    protected basePath = environment.filestoreUrl;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || this.basePath;

        } else {
            this.configuration.basePath = this.basePath;
        }
    }

    public apiV1TemporaryImagesAddTemporaryImagesToFileStoreAsyncPost(apiVersion: string, requestBody?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiV1TemporaryImagesAddTemporaryImagesToFileStoreAsyncPost(apiVersion: string, requestBody?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiV1TemporaryImagesAddTemporaryImagesToFileStoreAsyncPost(apiVersion: string, requestBody?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiV1TemporaryImagesAddTemporaryImagesToFileStoreAsyncPost(apiVersion: string, requestBody?: Array<string>, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling apiV1TemporaryImagesAddTemporaryImagesToFileStoreAsyncPost.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (apiVersion !== undefined && apiVersion !== null) {
            queryParameters = queryParameters.set('api-version', <any>apiVersion);
        }

        let headers = this.defaultHeaders;

        // authentication (ImplicitOAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<boolean>(`${this.configuration.basePath}/api/v1/TemporaryImages/AddTemporaryImagesToFileStoreAsync`,
            requestBody,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    public apiV1TemporaryImagesDeleteTemporaryImagesAsyncPost(apiVersion: string, filesIds?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiV1TemporaryImagesDeleteTemporaryImagesAsyncPost(apiVersion: string, filesIds?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiV1TemporaryImagesDeleteTemporaryImagesAsyncPost(apiVersion: string, filesIds?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiV1TemporaryImagesDeleteTemporaryImagesAsyncPost(apiVersion: string, filesIds?: Array<string>, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling apiV1TemporaryImagesDeleteTemporaryImagesAsyncPost.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (filesIds) {
            filesIds.forEach((element) => {
                queryParameters = queryParameters.append('filesIds', <any>element);
            });
        }
        if (apiVersion !== undefined && apiVersion !== null) {
            queryParameters = queryParameters.set('api-version', <any>apiVersion);
        }

        let headers = this.defaultHeaders;

        // authentication (ImplicitOAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<boolean>(`${this.configuration.basePath}/api/v1/TemporaryImages/DeleteTemporaryImagesAsync`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    public apiV1TemporaryImagesGetTemporaryImagesByOwnerAsyncKeyItemKeyGet(itemKey: string, apiVersion: string, observe?: 'body', reportProgress?: boolean): Observable<Array<TemporaryFileReadModel>>;
    public apiV1TemporaryImagesGetTemporaryImagesByOwnerAsyncKeyItemKeyGet(itemKey: string, apiVersion: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TemporaryFileReadModel>>>;
    public apiV1TemporaryImagesGetTemporaryImagesByOwnerAsyncKeyItemKeyGet(itemKey: string, apiVersion: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TemporaryFileReadModel>>>;
    public apiV1TemporaryImagesGetTemporaryImagesByOwnerAsyncKeyItemKeyGet(itemKey: string, apiVersion: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (itemKey === null || itemKey === undefined) {
            throw new Error('Required parameter itemKey was null or undefined when calling apiV1TemporaryImagesGetTemporaryImagesByOwnerAsyncKeyItemKeyGet.');
        }
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling apiV1TemporaryImagesGetTemporaryImagesByOwnerAsyncKeyItemKeyGet.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (apiVersion !== undefined && apiVersion !== null) {
            queryParameters = queryParameters.set('api-version', <any>apiVersion);
        }

        let headers = this.defaultHeaders;

        // authentication (ImplicitOAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<TemporaryFileReadModel>>(`${this.configuration.basePath}/api/v1/TemporaryImages/GetTemporaryImagesByOwnerAsync/key/${encodeURIComponent(String(itemKey))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
