import { EventEmitter, Injectable } from '@angular/core';
import { HttpTransportType, HubConnection, HubConnectionBuilder, LogLevel } from '@aspnet/signalr';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/applazy/services/auth.service';
import { environment } from 'src/environments/environment';
import {
  DefaultPushNotificationModel,
  HotActionPushNotificationModel,
  PortionPushNotificationModel,
  PickupPushNotificationModel,
  DeliveryPushNotificationModel,
  ReservationPushNotificationModel,
  WithoutMenuPushNotificationModel,
  UniqueQRPushNotificationModel,
  KioskPushNotificationModel,
  NotificationReadModel,
  IPushNotificationModel,
  ENotificatoinOrderStatus
} from 'src/applazy/shared/models/notificationmodels';
import { User } from '../shared/models/User';
import { ConfigService } from './config.service';

@Injectable()
export class SignalRNotificationClient {
  unknown = new EventEmitter<{ model: DefaultPushNotificationModel, date: Date }>();
  hotAction = new EventEmitter<{ model: HotActionPushNotificationModel, date: Date }>();
  portion = new EventEmitter<{ model: PortionPushNotificationModel, date: Date }>();
  pickup = new EventEmitter<{ model: PickupPushNotificationModel, date: Date }>();
  delivery = new EventEmitter<{ model: DeliveryPushNotificationModel, date: Date }>();
  reservation = new EventEmitter<{ model: ReservationPushNotificationModel, date: Date }>();
  withoutMenu = new EventEmitter<{ model: WithoutMenuPushNotificationModel, date: Date }>();
  uniqueQr = new EventEmitter<{ model: UniqueQRPushNotificationModel, date: Date }>();
  kiosk = new EventEmitter<{ model: KioskPushNotificationModel, date: Date }>();
  newNotification = new EventEmitter<{ data: NotificationReadModel, notify: boolean }>();
  connectionEstablished = new EventEmitter<Boolean>();
  connectionExists = false;
  volumeOn = true;
  temporaryPortionNotifications: { checkoutId: string, count: number, checked: boolean }[] = [];

  private _hubConnection: HubConnection;

  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private configService: ConfigService
  ) {
    this.authService.authorized.subscribe(val => {
      if (val === false && this.connectionExists) {
        this._hubConnection.stop().then(() => {
          this.connectionExists = false;
        });
        this._hubConnection.off(NotificationReadModel.ActionTypeEnum.Unknown);
        this._hubConnection.off(NotificationReadModel.ActionTypeEnum.HotAction);
        this._hubConnection.off(NotificationReadModel.ActionTypeEnum.Portion);
        this._hubConnection.off(NotificationReadModel.ActionTypeEnum.Pickup);
        this._hubConnection.off(NotificationReadModel.ActionTypeEnum.Delivery);
        this._hubConnection.off(NotificationReadModel.ActionTypeEnum.Reservation);
        this._hubConnection.off(NotificationReadModel.ActionTypeEnum.WithoutMenu);
        this._hubConnection.off(NotificationReadModel.ActionTypeEnum.UniqueQR);
        this._hubConnection.off(NotificationReadModel.ActionTypeEnum.Kiosk);
      }
      if (val === true && !this.connectionExists) {
        this.authorize();
      }
    });
  }

  muteNotificationSound(volumeOn: boolean) {
    this.volumeOn = volumeOn;
  }

  private authorize() {
    let user = User.sub;
    if (User.Role === 'SalePortal') {
      user = this.configService.get('host');
    }
    if (User.Role === 'Subadmin') {
      user = User.team;
    }
    const url = environment.signalRUrl + '/notificationhub/?userId=' + user.toLowerCase();
    this._hubConnection = new HubConnectionBuilder()
      .configureLogging(LogLevel.None)
      .withUrl(url, {
        skipNegotiation: true,
        logger: LogLevel.None,
        logMessageContent: false,
        transport: HttpTransportType.WebSockets
      })
      .configureLogging(LogLevel.None)
      .build();
    // transport: HttpTransportType.LongPolling,
    this.registerOnServerEvents();

    this.startConnection();
    const self = this;
    this._hubConnection.onclose(() => {
      setTimeout(function () {
        self.startConnection();
      }, 5000);
    });

    //   }
    // });
  }

  private startConnection(): void {
    this._hubConnection
      .start()
      .then(() => {
        this.connectionExists = true;
        this.connectionEstablished.emit(true);
      })
      .catch(err => {
      });
  }

  private registerOnServerEvents(): void {

    this._hubConnection.on(NotificationReadModel.ActionTypeEnum.Unknown, (data: NotificationReadModel) => {
      this.setNotification(data);
      this.unknown.emit({ model: data.data, date: data.date });
    });
    this._hubConnection.on(NotificationReadModel.ActionTypeEnum.HotAction, (data: NotificationReadModel) => {
      if (data.dataModel) {
        const hotActionData = JSON.parse(data.dataModel) as HotActionPushNotificationModel;
        data.data = hotActionData;
      }
      data.title = data.actionTypeDisplayName.toLowerCase() + data.actionTypeDisplayName.substr(1);
      // data.message = hotActionData.groupNameKey;
      this.setNotification(data);
      this.hotAction.emit({ model: <HotActionPushNotificationModel>data.data, date: data.date });

    });
    this._hubConnection.on(NotificationReadModel.ActionTypeEnum.Portion, (data: NotificationReadModel) => {
      let portionData: PortionPushNotificationModel;
      if (data.dataModel) {
        portionData = JSON.parse(data.dataModel) as PortionPushNotificationModel;
        data.data = portionData;
      }
      if (portionData && portionData.status === ENotificatoinOrderStatus.ActiveOrderCheckout) {
        let index = this.temporaryPortionNotifications.findIndex(x => x.checkoutId === portionData.checkoutId);
        if (index === -1) {
          const n = {
            checkoutId: portionData.checkoutId,
            checked: false,
            count: 0
          };
          index = this.temporaryPortionNotifications.length;
          this.temporaryPortionNotifications.push(n);
        }
        this.temporaryPortionNotifications[index].count++;
        this.setNotification(data, this.temporaryPortionNotifications[index].count !== 1);
      } else {
        let notify = false;
        if (portionData && portionData.status === ENotificatoinOrderStatus.Completed) {
          notify = true;
        }
        this.setNotification(data, notify);
      }
      this.portion.emit({ model: <PortionPushNotificationModel>data.data, date: data.date });

    });
    this._hubConnection.on(NotificationReadModel.ActionTypeEnum.Pickup, (data: NotificationReadModel) => {
      if (data.dataModel) {
        const pickupData = JSON.parse(data.dataModel) as PickupPushNotificationModel;
        data.data = pickupData;
      }
      this.setNotification(data);
      this.pickup.emit({ model: <PickupPushNotificationModel>data.data, date: data.date });

    });
    this._hubConnection.on(NotificationReadModel.ActionTypeEnum.Delivery, (data: NotificationReadModel) => {
      if (data.dataModel) {
        const deliveryData = JSON.parse(data.dataModel) as DeliveryPushNotificationModel;
        data.data = deliveryData;
      }
      this.setNotification(data);
      this.delivery.emit({ model: <DeliveryPushNotificationModel>data.data, date: data.date });
    });
    this._hubConnection.on(NotificationReadModel.ActionTypeEnum.Reservation, (data: NotificationReadModel) => {
      if (data.dataModel) {
        const reservationData = JSON.parse(data.dataModel) as ReservationPushNotificationModel;
        data.data = reservationData;
      }
      this.setNotification(data);
      this.reservation.emit({ model: <ReservationPushNotificationModel>data.data, date: data.date });
    });
    this._hubConnection.on(NotificationReadModel.ActionTypeEnum.WithoutMenu, (data: NotificationReadModel) => {
      if (data.dataModel) {
        const withoutMenuData = JSON.parse(data.dataModel) as WithoutMenuPushNotificationModel;
        data.data = withoutMenuData;
      }
      this.setNotification(data);
      this.withoutMenu.emit({ model: <WithoutMenuPushNotificationModel>data.data, date: data.date });
    });
    this._hubConnection.on(NotificationReadModel.ActionTypeEnum.UniqueQR, (data: NotificationReadModel) => {
      if (data.dataModel) {
        const uniqueQRData = JSON.parse(data.dataModel) as UniqueQRPushNotificationModel;
        data.data = uniqueQRData;
      }
      this.setNotification(data);
      this.uniqueQr.emit({ model: <UniqueQRPushNotificationModel>data.data, date: data.date });
    });
    this._hubConnection.on(NotificationReadModel.ActionTypeEnum.Kiosk, (data: NotificationReadModel) => {
      if (data.dataModel) {
        const kioskData = JSON.parse(data.dataModel) as KioskPushNotificationModel;
        data.data = kioskData;
      }
      this.setNotification(data);
      this.kiosk.emit({ model: <KioskPushNotificationModel>data.data, date: data.date });
    });
  }

  private setNotification(data: NotificationReadModel, notify = true) {
    if (!notify) {
      this.newNotification.emit({ data: data, notify: false });
      return;
    }
    const title = data.actionTypeDisplayName;
    this.playAudio();
    if (!title) {
      this.toastr.info(data.message, data.title);
      this.newNotification.emit({ data: data, notify: true });
      return;
    }
    this.translateService.get(title).subscribe(t => {
      this.toastr.info('', title);
    });
    this.newNotification.emit({ data: data, notify: true });
  }

  private playAudio() {
    const audio = new Audio();
    audio.src = './../../../assets/audio/sound.mp3';
    audio.load();
    audio.play();

    audio.muted = !this.volumeOn;
  }

  public toPushNotificationModel(data: IPushNotificationModel) {
    switch (data.actionType) {
      case NotificationReadModel.ActionTypeEnum.Unknown:
        return data;
      case NotificationReadModel.ActionTypeEnum.Portion:
        return <PortionPushNotificationModel>data;
      case NotificationReadModel.ActionTypeEnum.Pickup:
        return <PickupPushNotificationModel>data;
      case NotificationReadModel.ActionTypeEnum.Delivery:
        return <DeliveryPushNotificationModel>data;
      case NotificationReadModel.ActionTypeEnum.Reservation:
        return <ReservationPushNotificationModel>data;
      case NotificationReadModel.ActionTypeEnum.WithoutMenu:
        return <WithoutMenuPushNotificationModel>data;
      case NotificationReadModel.ActionTypeEnum.UniqueQR:
        return <UniqueQRPushNotificationModel>data;
        case NotificationReadModel.ActionTypeEnum.Kiosk:
          return <KioskPushNotificationModel>data;
      default:
        break;
    }
  }
}
