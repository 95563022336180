import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DomSanitizerDirective } from './directives/domSanitizer.directive';
import { XssSanitizerPipe } from './pipes/xssSanitizer.pipe';
import { XssSanitizerService } from './services/xssSanitizer.service';

@NgModule({
    imports: [CommonModule],
    declarations: [
        DomSanitizerDirective,
        XssSanitizerPipe
    ],
    exports: [
        DomSanitizerDirective,
        XssSanitizerPipe
    ],
    providers: [
        XssSanitizerService
    ]
})
export class SanitizerModule { }
