import { Injectable } from '@angular/core';
import { IconService } from './icon.service';

@Injectable()
export class ImageCloudDataService {
  private _apiVersion = '1.0';
  private _pageSize = 30;

  get pageSize() {
    return this._pageSize;
  }

  constructor(
    private iconService: IconService
  ) {

  }

  getIcons(searchText: string) {
    return this.iconService.apiVapiVersionIconGetIconsGet(this._apiVersion, searchText, 0, this._pageSize);
  }

  loadMoreIcons(searchText: string, pageIndex: number) {
    return this.iconService.apiVapiVersionIconGetIconsGet(this._apiVersion, searchText, pageIndex, this._pageSize);
  }
}
export interface PaginatedItemsReadModelOfIconReadModel {
  pageIndex?: number;
  pageSize?: number;
  count?: number;
  data?: Array<IconReadModel>;
}

export interface IconReadModel {
  id?: number;
  url?: string;
}
