import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { PaginatedItemReadModel } from "../models/paginatedItemReadModel";
import { IPartnerReadModel } from "../models/partner/partner.model";
import { SalePortalInfoService } from "./salePortalInfo.service";

@Injectable()
export class SalePortalInfoDataService {
    private _apiVersion = '1.0';
    constructor(private salePortalInfoService: SalePortalInfoService) {

    }
    partners(searchText: string, pageSize: number, pageIndex: number): Observable<PaginatedItemReadModel<IPartnerReadModel>> {
        return this.salePortalInfoService.partners(
            this._apiVersion,
            searchText,
            undefined,
            undefined,
            pageSize,
            pageIndex,
            0,
            undefined,
            undefined).pipe(map(res => {
                return res.partnerPaginatedItemsReadModel;
            }))
    }
    partnersByKeys(keys: Array<string>): Observable<PaginatedItemReadModel<IPartnerReadModel>> {
        return this.salePortalInfoService.partnersByKeys(
            '1',
            keys
        ).pipe(map(res => {
            return res.partnerPaginatedItemsReadModel;
        }))
    }
}