import { Observable } from 'rxjs';
import { Injectable, HostListener, OnDestroy, Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, CanDeactivate } from '@angular/router';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NavigationService } from '../shared/services/navigation.service';
import { User } from '../shared/models/User';
import { ComparableModel } from '../shared/models/compare/comparable.model';
export interface ICanComponentDeactivate {
    canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
    back: (navigationService: NavigationService, url: string) => void;
}

@Component({
    template: ''
})
export class CanComponentDeactivateComponent implements OnInit, ICanComponentDeactivate {
    fromBack = false;
    comparableTabId = '';

    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: BeforeUnloadEvent) {
        if (!this.navigationService.compareWithOld(this)) {
            $event.returnValue = true;
        }
    }
    constructor(
        public navigationService: NavigationService,
        public activatedRoute: ActivatedRoute,
        @Inject('url') public url: string
    ) {

    }

    ngOnInit(): void {
        this.resetChanges();
    }

    resetChanges(): void {
        this.comparableTabId = '';
        this.navigationService.component = null;
        this.navigationService.beforeChanges = [];
        this.navigationService.beforeChanges = this.navigationService.getComparableArr(this);
    }

    canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
        if (this.fromBack) {
            this.fromBack = false;
            return true;
        }
        if (this.comparableTabId) {
            this.comparableTabId = '';
            return false;
        }
        if (!this.navigationService.compareWithOld(this)) {
            const allowRefresh = confirm('Changes you made may not be saved.');
            if (allowRefresh) {
                this.resetChanges();
            }
            return allowRefresh;
        } else {
            return true;
        }
    }
    back() {
        this.fromBack = true;
        let r = '';
        if (this.url !== '/home') {
            r = User.currentRole;
        }
        const routeUrl = User.routerUrl(this.activatedRoute);
        const url = r + routeUrl + this.url;
        const a = this.navigationService.navigateWithCompareMessage(url);
    }

    destroy() {
        this.navigationService.beforeChanges = [];
    }
}

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<ICanComponentDeactivate> {
    canDeactivate(
        component: ICanComponentDeactivate,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return component.canDeactivate ? component.canDeactivate() : true;
    }
} 
