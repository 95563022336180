
import { Inject, Injectable, Optional } from '@angular/core';
import {
    HttpClient, HttpHeaders, HttpParams,
    HttpResponse, HttpEvent
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Configuration } from '../../models/configuration';
import { CustomHttpUrlEncodingCodec } from '../../models/encoder';


@Injectable({
    providedIn: 'root'
})
export class ImageStoreService {

    protected basePath = environment.filestoreUrl;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || this.basePath;

        } else {
            this.configuration.basePath = this.basePath;
        }
    }

    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    public apiVapiVersionImageStoreCloneGet(apiVersion: string, oldId?: string, newId?: string, fileSize?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiVapiVersionImageStoreCloneGet(apiVersion: string, oldId?: string, newId?: string, fileSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiVapiVersionImageStoreCloneGet(apiVersion: string, oldId?: string, newId?: string, fileSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiVapiVersionImageStoreCloneGet(apiVersion: string, oldId?: string, newId?: string, fileSize?: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling apiVapiVersionImageStoreCloneGet.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (oldId !== undefined && oldId !== null) {
            queryParameters = queryParameters.set('oldId', <any>oldId);
        }
        if (newId !== undefined && newId !== null) {
            queryParameters = queryParameters.set('newId', <any>newId);
        }
        if (fileSize !== undefined && fileSize !== null) {
            queryParameters = queryParameters.set('fileSize', <any>fileSize);
        }

        let headers = this.defaultHeaders;

        // authentication (ImplicitOAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.configuration.basePath}/api/v${encodeURIComponent(String(apiVersion))}/ImageStore/Clone`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    public apiVapiVersionImageStoreDeleteImageDelete(apiVersion: string, filename?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiVapiVersionImageStoreDeleteImageDelete(apiVersion: string, filename?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiVapiVersionImageStoreDeleteImageDelete(apiVersion: string, filename?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiVapiVersionImageStoreDeleteImageDelete(apiVersion: string, filename?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling apiVapiVersionImageStoreDeleteImageDelete.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (filename !== undefined && filename !== null) {
            queryParameters = queryParameters.set('filename', <any>filename);
        }

        let headers = this.defaultHeaders;

        // authentication (ImplicitOAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.configuration.basePath}/api/v${encodeURIComponent(String(apiVersion))}/ImageStore/DeleteImage`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    public apiVapiVersionImageStoreDownloadImageAsBase64Get(apiVersion: string, fileId?: string, fileSize?: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public apiVapiVersionImageStoreDownloadImageAsBase64Get(apiVersion: string, fileId?: string, fileSize?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public apiVapiVersionImageStoreDownloadImageAsBase64Get(apiVersion: string, fileId?: string, fileSize?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public apiVapiVersionImageStoreDownloadImageAsBase64Get(apiVersion: string, fileId?: string, fileSize?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling apiVapiVersionImageStoreDownloadImageAsBase64Get.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (fileId !== undefined && fileId !== null) {
            queryParameters = queryParameters.set('fileId', <any>fileId);
        }
        if (fileSize !== undefined && fileSize !== null) {
            queryParameters = queryParameters.set('fileSize', <any>fileSize);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<string>(`${this.configuration.basePath}/api/v${encodeURIComponent(String(apiVersion))}/ImageStore/DownloadImageAsBase64`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    public apiVapiVersionImageStoreDownloadResizedOrOriginalImageGet(apiVersion: string, fileId?: string, fileSize?: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiVapiVersionImageStoreDownloadResizedOrOriginalImageGet(apiVersion: string, fileId?: string, fileSize?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiVapiVersionImageStoreDownloadResizedOrOriginalImageGet(apiVersion: string, fileId?: string, fileSize?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiVapiVersionImageStoreDownloadResizedOrOriginalImageGet(apiVersion: string, fileId?: string, fileSize?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling apiVapiVersionImageStoreDownloadResizedOrOriginalImageGet.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (fileId !== undefined && fileId !== null) {
            queryParameters = queryParameters.set('fileId', <any>fileId);
        }
        if (fileSize !== undefined && fileSize !== null) {
            queryParameters = queryParameters.set('fileSize', <any>fileSize);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get(`${this.configuration.basePath}/api/v${encodeURIComponent(String(apiVersion))}/ImageStore/DownloadResizedOrOriginalImage`,
            {
                params: queryParameters,
                responseType: 'blob',
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    public uploadImage(apiVersion: string, uploadTargetId?: string, urlType?: 'DownloadUrl' | 'BlobUrl', file?: Blob, uploadOrigin?: boolean, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public uploadImage(apiVersion: string, uploadTargetId?: string, urlType?: 'DownloadUrl' | 'BlobUrl', file?: Blob, uploadOrigin?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public uploadImage(apiVersion: string, uploadTargetId?: string, urlType?: 'DownloadUrl' | 'BlobUrl', file?: Blob, uploadOrigin?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public uploadImage(apiVersion: string, uploadTargetId?: string, urlType?: 'DownloadUrl' | 'BlobUrl', file?: Blob, uploadOrigin?: boolean, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling uploadImage.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (uploadTargetId !== undefined && uploadTargetId !== null) {
            queryParameters = queryParameters.set('uploadTargetId', <any>uploadTargetId);
        }
        if (urlType !== undefined && urlType !== null) {
            queryParameters = queryParameters.set('urlType', <any>urlType);
        }
        if (uploadOrigin !== undefined && uploadOrigin !== null) {
            queryParameters = queryParameters.set('uploadOrigin', <any>uploadOrigin);
        }

        let headers = this.defaultHeaders;

        // authentication (ImplicitOAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        const convertFormParamsToString = false;
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) || formParams;
        }

        return this.httpClient.post<string>(`${this.configuration.basePath}/api/v${encodeURIComponent(String(apiVersion))}/ImageStore/Upload`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
}
