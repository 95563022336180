import { Subject } from 'rxjs';
export class ErrorsService {
    errorCode: { code: number, message?: string };
    private error$ = new Subject<{ code: number, message?: string }>();
    error = this.error$.asObservable();
    constructor(

    ) {
    }

    addError(error: { code: number, message?: string }) {
        this.errorCode = error;
        this.error$.next(error);
    }
}
